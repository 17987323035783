@import "@mapix/common/src/assets/stylesheets/colors";

.divider {
  border: 1px solid $secondary40;
  width: 100%;
  margin-bottom: 40px;
}

.modalContainer {
  width: 344px;

  .buttonsWrapper {
    justify-content: space-between;

    > button {
      width: fit-content;
    }
  }

  .buttonsWrapperSlim {
    > button {
      width: fit-content;
      min-width: 0;
      margin-left: 16px;
    }
  }
}

.tabsContainer {
  margin-top: 30px;
}
