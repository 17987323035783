@import "@mapix/common/src/assets/stylesheets/colors";

.pillContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  border-radius: 20px;
  width: fit-content;
  white-space: nowrap;

  &.blue {
    background-color: $info30;
  }

  &.green {
    background-color: $tertiary50;
  }

  &.purple {
    background-color: $primary50;
  }

  &.red {
    background-color: $danger30;
  }

  &.yellow {
    background-color: $warning50;
  }

  &.gray {
    background-color: $secondary70;
  }

  &.white {
    background-color: $surface10;
    border: 1px solid $secondary40;
  }

  .icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }

  .alert {
    @extend .icon;

    filter: invert(96%) sepia(0%) saturate(437%) hue-rotate(282deg) brightness(123%) contrast(100%);
  }
}
