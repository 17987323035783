@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";
@import "assets/stylesheets/variables";

.titleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  text-align: center;
  margin-top: 0;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.errorMessage {
  top: $header-height;
  left: 0;
}

.spinner {
  min-height: $page-min-height;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  margin-top: 10px;
}

.title {
  margin-bottom: 0;
}

.blue {
  color: $primary50;
}

.google-login-wrapper {
  margin-bottom: 24px;
}

.registerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $surface10;
  width: 376px;
  height: auto;
  z-index: 1;
  border: 1px solid $secondary50;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px;

  @include mobile {
    width: 344px;
  }
}

.row {
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

p {
  margin-top: 0;
  margin-right: 5px;
}

.green {
  color: $tertiary50;
}

.greenBold {
  @extend .green;

  font-weight: bold;
}

.bottomText {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  z-index: $z-index-welcome-title;
}

.littleMargin {
  margin-bottom: 8px;
}
