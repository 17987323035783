@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.modalContainer {
  background-color: $surface10;
  border-radius: 8px;
  padding: 24px;
  width: 344px;
  border: 1px solid $secondary50;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.body {
  margin-top: 24px;
  white-space: pre-line;

  @include mobile {
    width: 100%;
  }
}

.count {
  display: flex;
  justify-content: flex-end;
}

.titleAndCrossContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.crossIcon {
  width: 20px;
  height: 20px;
  filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);

  @include mobile {
    margin-top: 0;
    margin-right: 0;
  }
}

p {
  margin: 0;
}

.semiBold {
  font-weight: 600;
}

.buttonsWrapper {
  margin-top: 24px;
  display: flex;
  direction: row;
  width: 100%;
  justify-content: flex-end;
}

.cancel {
  width: 110px;

  >.ghostSecondary {
    padding-left: 0;
  }
}

.buttonRight {
  height: 48px;
  min-width: 110px;
}
