@import "@mapix/common/src/assets/stylesheets/colors";

.formTitle {
  margin-top: 56px;
}

.divider {
  margin-top: 56px;
  margin-bottom: 40px;
  color: $secondary50;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.leaseSubtitle {
  margin-top: 14px;
  display: flex;
  width: 100%;
}

.alertIcon {
  margin-right: 12px;
}
