@import "../../assets/stylesheets/colors";

.progressBarContainer {
  margin-bottom: 8px;

  .progressBar {
    height: 6px;
    width: 174px;
    background-color: $surface20;
    border-radius: 5px;
    margin-right: 8px;

    .filler {
      height: 6px;
      background-color: $primary50;
      border-radius: inherit;
    }
  }

  .check {
    height: 12px;
    width: 12px;
  }

  .updating {
    margin-right: 12px;
  }
}
