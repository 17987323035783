@import "../../assets/stylesheets/colors";

.toggleSwitch {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 24px;
  border-radius: 50px;
}

.toggleSwitch input[type="checkbox"] {
  display: none;
}

.toggleSwitch .switch {
  position: absolute;
  cursor: pointer;
  background-color: $secondary40;
  border: 2px solid $secondary40;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggleSwitch .switch::before {
  position: absolute;
  content: "";
  left: 4px;
  top: 2px;
  width: 16px;
  height: 16px;
  background-color: $secondary10;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggleSwitch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(18px);
}

.toggleSwitch input[type="checkbox"]:checked + .switch {
  background-color: $primary50;
  border-color: $primary50;
}

.check {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 6px;
}
