@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.selectContainer {
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: $select-z-index;
    padding: 4px 8px;
    background-color: $surface10;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    box-shadow: 0 2px 19px 1px $surface50;
    border-radius: 0 0 8px 8px;

    .item {
      text-align: left;
      padding: 10px 8px;
      border-bottom: 1px solid $secondary50;

      &:hover {
        background-color: $secondary20;
      }

      &:active {
        background-color: $secondary30;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.button {
  width: 100%;
  height: 40px;
}

.chevron {
  position: relative;
  width: 24px;
  height: 24px;
  filter: invert(37%) sepia(16%) saturate(4695%) hue-rotate(225deg) brightness(93%) contrast(85%);
}

.buttonContent {
  display: flex;
  align-items: center;
}

.iconDelete {
  width: 56px;
  height: 56px;
  filter: invert(42%) sepia(48%) saturate(2002%) hue-rotate(227deg) brightness(92%) contrast(100%);
}
