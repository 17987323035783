@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: $footer-height;
  background-color: $primary50;
  padding: 0 40px;

  @include mobile {
    padding: 0;
  }
}

.left {
  display: flex;
  width: 30%;
  justify-content: flex-start;

  @include mobile {
    width: 55%;
  }
}

.right {
  display: flex;
  width: 65%;
  justify-content: flex-end;

  @include mobile {
    width: 45%;
  }
}

.link {
  text-decoration: none;
  margin-left: 40px;

  @include mobile {
    margin-left: 14px;
    font-size: 12px;
  }
}

p {
  padding: 0;
  margin: 0;
}

.rights {
  @include mobile {
    font-size: 10px;
  }
}
