@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";
@import "assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .detail {
    padding: 24px $create-side-padding 80px $create-side-padding;
    min-height: $page-min-height;
  }

  .buttonRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 41px;

    .endButton {
      margin-left: 24px;
      margin-right: 24px;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: row;
    }
  }

  .contentTitle {
    margin-top: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .addressSubtitle {
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .line {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    border: 1px solid $secondary50;
  }

  .plusIcon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    filter: invert(99%) sepia(9%) saturate(248%) hue-rotate(334deg) brightness(119%) contrast(100%);
  }
}
