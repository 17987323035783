@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerCircle {
  display: flex;
  position: relative;
  width: 120px;
  height: 120px;
  background-color: $primary20;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.containerIcon {
  position: absolute;
  right: 8px;
  bottom: 10px;
  width: 24px;
  height: 24px;
  border-radius: 60px;
}

.plusIcon {
  height: 20px;
  width: 20px;
}

.icon {
  width: 48px;
  height: 48px;
}

.link {
  text-decoration: underline;
  margin-top: 8px;
}
