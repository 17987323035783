@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 40px $create-side-padding 0 $create-side-padding;
  width: 100%;
  position: relative;
}

.icon {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-left: 32px;
}

.titleRow {
  display: flex;
  align-items: center;
}

.iconNew {
  @extend .icon;

  width: 79px;
  background-color: $warning10;
  color: $warning50;
}

.cancelAssignemntModal {
  filter: invert(45%) sepia(21%) saturate(2976%) hue-rotate(220deg) brightness(93%) contrast(98%);
  width: 45px;
  height: 45px;
}

.iconInProgress {
  @extend .icon;

  width: 126px;
  background-color: $info10;
  color: $info50;
}

.iconSolved {
  @extend .icon;

  width: 95px;
  background-color: $info10;
  color: $info50;
}

.iconRejected {
  @extend .icon;

  width: 109px;
  background-color: $danger10;
  color: $danger50;
}

.row {
  display: flex;
  flex-direction: row;
}

.card {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
}

.cardDescription {
  @extend .card;

  width: 60%;
}

.margin {
  margin-top: 20px;
}

.table {
  width: 100%;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
}

.photos {
  margin-top: 24px;
  display: flex;
  width: 90%;
  flex-wrap: wrap;
}

.photo {
  width: 210px;
  height: 210px;
  margin: 10px 20px 10px 0;
}

.bold {
  font-weight: bold;
}

.button {
  color: $surface10;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.buttons {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 30px;
}

.closeIcon {
  filter: invert(20%) sepia(87%) saturate(2204%) hue-rotate(341deg) brightness(92%) contrast(89%);
  margin-left: 5px;
}

.dollarIcon {
  margin-left: 5px;
  filter: invert(99%) sepia(1%) saturate(2109%) hue-rotate(229deg) brightness(119%) contrast(100%);
}

.buttonsTop {
  @extend .row;

  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.iconEdit {
  margin-left: 5px;
  width: 17px;
  height: 17px;
}

.iconPrimary {
  @extend .iconEdit;

  filter: invert(50%) sepia(61%) saturate(488%) hue-rotate(211deg) brightness(101%) contrast(87%);
}

.buttonText {
  margin-left: 10px;
}

.italic {
  margin-top: 20px;
  font-style: italic;
}

.leftText {
  width: 50%;
}

.select {
  margin-top: 24px;
  width: 320px;
}

.checkIcon {
  margin-left: 8px;
}

.iconBlue {
  width: 44px;
  height: 44px;
  filter: invert(39%) sepia(82%) saturate(528%) hue-rotate(211deg) brightness(87%) contrast(91%);
}

.photoRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > * {
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }

  .img {
    cursor: pointer;
    width: 210px;
    height: 210px;
    object-fit: contain;
    object-position: center;
  }
}
