.billsContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 16px;
  width: 100%;

  .title {
    margin-bottom: 8px;
  }

  .subTitle {
    margin-bottom: 16px;
  }
}
