@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(#{$min-height} - #{$footer-height} - #{$header-height});
  padding: 32px 40px 40px;
  margin-bottom: 40px;

  p {
    display: block;
    margin: 1em 0;
  }

  h1 {
    font-weight: 700;
  }

  h2 {
    font-weight: 300;
  }
}

.withoutSpace {
  p {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.bold {
  font-weight: bold;
}

.title {
  @extend .bold;

  line-height: 56px;
}

.tableContainer {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
  justify-content: center;

  p {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  table,
  td {
    border: 1px solid $grey;
    border-collapse: collapse;
    padding-left: 10px;
  }

  .titles {
    background-color: $blackD;
    font-weight: bold;
  }
}

.tableContent {
  width: 800px;
}
