@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 40px 8px;
  background-color: $surface20;
  min-height: $page-min-height;

  .close {
    margin-left: auto;
  }

  .title {
    margin-bottom: 8px;
  }

  .subtitle {
    margin-bottom: 24px;
  }

  .formContainer {
    margin-top: 56px;

    .inputRow {
      display: flex;

      > * {
        margin-right: 24px;
      }
    }

    .radioRow {
      display: flex;
      align-self: center;

      .radioNo {
        display: flex;
        align-items: center;
        margin-right: 56px;
      }

      .radioYes {
        display: flex;
        align-items: center;
        margin-right: 24px;
      }
    }

    .label {
      margin-top: 72px;
      margin-bottom: 24px;
    }
  }

  .input {
    width: 280px;
  }

  .inputSmall {
    width: 144px;
  }
}

.check {
  margin-left: 8px;
}
