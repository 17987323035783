@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "assets/stylesheets/variables";

$inputWidth: 580px;

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  width: 100%;
  position: relative;
}

.queryLabel {
  margin: 24px 0;
}

.input {
  width: 280px;
}

.smallInput {
  width: 144px;
}

.largeInput {
  width: $inputWidth;
}

.searchColor {
  filter: invert(56%) sepia(77%) saturate(5538%) hue-rotate(232deg) brightness(97%) contrast(89%);
}

.emptyContent {
  background: $surface10;
  padding: 14px 26px;
}

.inputContainer {
  position: relative;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: $inputWidth;
  max-height: 580px;
  overflow: auto;
  z-index: $z-index-unit-numbers;
  background: $surface10;
  box-shadow: 0 2px 19px 1px $surface50;
  border-radius: 0 0 8px 8px;
  padding: 4px 8px;

  .item {
    text-align: left;
    padding: 10px 8px;
    border-bottom: 1px solid $secondary50;

    &:hover {
      background-color: $secondary20;
    }

    &:active {
      background-color: $secondary30;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.formTitle {
  margin-top: 64px;
  margin-bottom: 32px;
}

.addressTitle {
  margin-bottom: 24px;
  margin-top: 8px;
}

.radios {
  display: flex;
  align-items: center;
  margin-right: 56px;

  .label {
    margin-left: 21px;
  }

  &.second {
    margin-right: 24px;
  }
}

.textarea {
  width: 710px;
}

.row {
  display: flex;
  align-items: center;

  > * {
    margin-right: 24px;
  }
}

.addButton {
  margin-top: 40px;
  margin-bottom: 80px;
}

.check {
  margin-left: 8px;
}

.leaveIcon {
  filter: invert(37%) sepia(73%) saturate(1047%) hue-rotate(223deg) brightness(98%) contrast(87%);
}

.icons {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: flex;
  align-items: center;

  &.closeIcon {
    filter: invert(37%) sepia(14%) saturate(4934%) hue-rotate(223deg) brightness(96%) contrast(85%);
  }
}

.cancelButton {
  margin-right: 24px;
}

.buttonAlign {
  display: flex;
  align-items: center;
}

.buttons {
  margin-top: 40px;
}
