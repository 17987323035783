@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: $surface10;
  width: fit-content;
  padding: 32px;
  border-radius: 8px;
}

.buttons {
  margin-top: 48px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;

  .button {
    height: 40px;
    margin-left: 20px;
    min-width: 184px;
    width: fit-content;
  }
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 200px;
}

.containerIcon {
  width: 120px;
  height: 120px;
  margin-bottom: 16px;
  background-color: $danger05;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon {
  width: 56px;
  height: 56px;
  filter: invert(23%) sepia(89%) saturate(1565%) hue-rotate(333deg) brightness(118%) contrast(90%);
}

.subtitle {
  margin-top: 32px;
  margin-bottom: 40px;
}

.count {
  display: flex;
  justify-content: flex-end;
}

.crossContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.crossIcon {
  width: 20px;
  height: 20px;
  filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
}
