@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.paymentCardContainer {
  margin-top: 24px;
}

.paymentCard {
  background: $surface10;
  width: 80%;
  border-radius: 8px;

  &.payModal {
    width: 100%;
    padding: 16px;
    margin-bottom: 24px;
  }

  @include mobile {
    width: 100%;
    display: flex;
    margin-bottom: 18px;
    flex-direction: column;
  }

  .rentContainer {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .rentTitle {
    margin-right: 48px;

    @include mobile {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .separator {
    border: 1px solid $secondary60;
    margin: 0 8px;

    &MoreMargin {
      @extend .separator;

      margin: 0 24px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chipContainer {
    padding: 5px 24px;
    border-radius: 24px;

    @include mobile {
      align-self: start;
      margin-left: 16px;
    }

    &.danger {
      background-color: $danger10;
    }

    &.onTime {
      background-color: $info10;
    }

    &.paid {
      background-color: $success10;
    }
  }

  .infoButton {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .infoIcon {
    margin-left: 8px;
    filter: invert(89%) sepia(8%) saturate(241%) hue-rotate(169deg) brightness(91%) contrast(91%);
  }
}
