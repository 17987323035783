@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.browserContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .photoRow {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-top: 10px;
    width: 100%;

    @include mobile {
      margin-top: 0;
      flex-wrap: nowrap;
      overflow: auto;
    }
  }

  .inputLabel {
    align-self: flex-start;
    margin: 24px 0;
    cursor: pointer;
  }

  .icon {
    height: 24px;
    width: 24px;
    filter: invert(41%) sepia(82%) saturate(2435%) hue-rotate(230deg) brightness(85%) contrast(95%);
  }

  .input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .bold {
    font-weight: bold;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .alertIcon {
    height: 24px;
    width: 24px;
    margin-left: 10px;
  }

  .iconDisabled {
    height: 24px;
    width: 24px;
    filter: invert(96%) sepia(5%) saturate(148%) hue-rotate(156deg) brightness(86%) contrast(85%);
  }

  .title {
    margin-bottom: 12px;

    @include mobile {
      margin-bottom: 8px;
    }
  }

  .helperText {
    margin-top: 8px;

    @include mobile {
      margin-left: 16px;
    }
  }
}

.browseFiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $info10;
  width: 100%;
  border: 1px dashed $info30;
  border-radius: 4px;
  height: 120px;

  .uploadCloud {
    filter: invert(53%) sepia(49%) saturate(895%) hue-rotate(190deg) brightness(101%) contrast(92%);
    height: 64px;
    width: 100px;
    stroke-width: 1px;
  }

  .browseFilesText {
    margin-top: -6px;
  }
}

.label {
  display: flex;
  height: fit-content;
  width: 100%;
}
