@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "assets/stylesheets/variables";

.img {
  width: 100%;
  height: 90%;
  position: absolute;
}

.titleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  text-align: center;
  margin-top: 0;
  z-index: $z-index-welcome-title;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.spinner {
  min-height: $page-min-height;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  margin-top: 10px;
}

.loginTitle {
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 0;
}

.blue {
  color: $primary50;
}

.loginForm {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 376px;
  height: 528px;
  padding: 40px;
  z-index: 1;
  border: 1px solid $secondary50;
  box-sizing: border-box;
  border-radius: 8px;
}

.input {
  margin-top: 32px;
}

.marginTop {
  margin-top: 32px;
}

.row {
  display: flex;
  flex-direction: row;
}

p {
  margin-top: 0;
  margin-right: 5px;
}

.button {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 36px;
}

.textNeedAccount {
  @extend p;

  margin-bottom: 0;
}

.errorMessage {
  top: 55px;
  left: 10px;
}

.buttonLink {
  @extend .blue;

  text-decoration: underline $primary40;
}

.googleContainer {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}
