@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "assets/stylesheets/variables";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;
}

.cross {
  width: 24px;
  height: 24px;
  filter: invert(38%) sepia(82%) saturate(595%) hue-rotate(214deg) brightness(87%) contrast(95%);
}

.closeButton {
  margin-left: auto;
}

.subTitle {
  align-self: center;
  margin-top: 78px;
  margin-bottom: 40px;
}

.cardRow {
  align-self: center;

  > * {
    margin-right: 20px;
  }
}

.card {
  padding: 78px 26px 18px;
  background-color: $surface10;
  box-shadow: 0 2px 4px $surface10;
  border-radius: 8px;
  width: 325px;
  text-align: center;

  &:hover {
    box-shadow: 0 8px 16px $secondary30;
  }

  &:active {
    background-color: $primary10;
    box-shadow: 0 2px 4px $secondary10;
  }

  .title {
    margin-top: 32px;
    margin-bottom: 8px;
  }
}
