.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 114px;
  height: 98px;
  margin-top: 184px;
}

.gif {
  width: 100px;
  height: 100px;
}
