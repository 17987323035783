@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.modalContainer {
  background-color: $surface10;
  border-radius: 8px;
  padding: 38px 24px 0;

  @include mobile {
    width: 90%;
    padding: 24px 21px;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
}

.button {
  height: 40px;
  width: 184px;

  &.marginRight {
    margin-right: 8px;
  }
}

.containerIcon {
  width: 120px;
  height: 120px;
  background-color: $tertiary10;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 16px;

  @include mobile {
    margin-bottom: 16px;
  }
}

.icon {
  width: 56px;
  height: 56px;
  filter: invert(58%) sepia(57%) saturate(390%) hue-rotate(115deg) brightness(97%) contrast(90%);
}

.subtitle {
  margin-top: 10px;
  width: 364px;
  text-align: center;

  @include mobile {
    width: 100%;
  }
}

.count {
  display: flex;
  justify-content: flex-end;
}

.checkContainer {
  position: absolute;
  right: 6.47%;
  bottom: 12.5%;
  width: 24px;
  height: 24px;
  background-color: $tertiary50;
  border-radius: 60px;
}

.crossContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.crossIcon {
  width: 20px;
  height: 20px;
  filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);

  @include mobile {
    margin-top: 0;
    margin-right: 0;
  }
}

p {
  margin: 0;
}

.containerAlert {
  @extend .containerIcon;

  background-color: $primary10;
}

.alertIcon {
  position: absolute;
  right: 6.47%;
  bottom: 12.5%;
}

.oneButton {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
}
