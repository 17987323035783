@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.modal {
  width: 654px;
}

.container {
  margin: 42px 0 34px;
  width: 100%;

  .topInputsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @include mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;
    }

    .typeOfCostWrapper {
      display: flex;
      justify-content: space-between;

      .inputSmall {
        width: 48%;
        margin-bottom: 36px;
      }
    }
  }

  .divider {
    border: 1px solid $secondary40;
    width: 100%;
    margin-bottom: 20px;
  }
}
