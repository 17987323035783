@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  background-image: url("../../assets/images/login.jpg");
  background-size: cover;
  justify-content: center;
  align-items: center;
  min-height: calc(#{$min-height} - #{$footer-height} - #{$header-height});

  @include mobile {
    background-image: none;
    border-radius: 8px;
    width: 344px;
    height: 332px;
    background-color: $surface10;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 400px;
  background-color: $surface10;

  @include mobile {
    width: 100%;
    height: fit-content;
    padding: 24px;
    border: 1px solid $secondary50;
    border-radius: 8px;
  }
}

.blue {
  color: $primary50;
}

.titleMargin {
  margin-bottom: 24px;

  @include mobile {
    font-weight: 300;
  }
}

.subtitleMargin {
  margin-bottom: 16px;
}

.buttonLink {
  @extend .blue;

  text-decoration: underline $primary40;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.button {
  margin-top: 40px;
}
