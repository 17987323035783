@import "../../assets/stylesheets/colors";

.tabContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.textContainer {
  padding: 7px 16px 17px;
}

.activeText {
  font-weight: bold;
}

.activeLine {
  border: 1px solid $primary50;
  width: 100%;
}

.oneRowTab {
  display: inline-block;
  width: max-content;
}

.alert {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $warning50;
}
