@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "~leaflet/dist/leaflet.css";

.modalContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;

  @include mobile {
    width: 100%;
  }

  .map {
    height: 640px;
    border-radius: 8px;
    z-index: $map-z-index;
    width: 100%;

    @include mobile {
      height: 480px;
    }
  }

  .closeButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 66px;
    right: 16px;
    height: 48px;
    width: 48px;
    border-radius: 50%;

    // If defining opacity separately, it applies to the child element as well
    background-color: rgb(255 255 255 / 30%);

    .cross {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }
  }
}

.smallMapButton {
  height: fit-content;
  width: fit-content;
  padding: 0;
  position: relative;

  .map {
    height: 128px;
    border-radius: 8px;
    z-index: $map-z-index;
  }

  .draggingSign {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 8px 16px;
    background-color: $primary50;
    border-radius: 8px;
    z-index: calc(#{ $map-z-index } + 1);

    .maximizeIcon {
      margin-left: 8px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(295deg) brightness(109%) contrast(101%);
    }
  }
}
