@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: $surface10;
  width: 750px;
  height: 500px;
  padding: 32px;
  border-radius: 8px;
}

.buttons {
  margin-top: 48px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.button {
  height: 40px;
  margin-left: 20px;
  width: 184px;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 200px;
}

.containerIcon {
  width: 120px;
  height: 120px;
  margin-bottom: 16px;
  background-color: $primary10;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon {
  width: 44px;
  height: 44px;
  filter: invert(39%) sepia(82%) saturate(528%) hue-rotate(211deg) brightness(87%) contrast(91%);
}

.alertIcon {
  position: absolute;
  right: 6.47%;
  bottom: 12.5%;
  fill: $danger50;
}

.subtitle {
  margin-top: 32px;
  margin-bottom: 40px;
}

.count {
  display: flex;
  justify-content: flex-end;
}

.crossContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.crossIcon {
  width: 20px;
  height: 20px;
  filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
}
