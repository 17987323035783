@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.options {
  margin-top: 61px;
  margin-left: 38px;
  margin-bottom: 122px;
  width: 100%;

  @include mobile {
    margin-top: 12px;
    margin-left: 16px;
    margin-bottom: 96px;
    width: 98%;
  }
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background-color: $surface20;
}

.mobileNextButton {
  width: 98%;
  margin-right: 0;
}
