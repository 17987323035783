@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.headerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  height: $header-height;
  padding: 11px 40px;

  @include mobile {
    padding: 8px 16px;
  }
}

.languageLinks {
  display: flex;
  margin-left: auto;

  div {
    margin-right: 8px;
  }
}

.selectedLanguage {
  text-decoration: underline $primary60;
}

.unselectedLanguage {
  text-decoration: none;
}

.nameContainer {
  margin-left: 32px;
  margin-right: 18px;

  @include mobile {
    display: none;
  }
}

.avatar {
  width: 42px;
  height: 42px;
  background: $secondary60;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-decoration: none;

  @include mobile {
    width: 32px;
    height: 32px;
  }
}

.logo {
  height: 32px;
  width: auto;
  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 18px;

  @include mobile {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    margin-right: 16px;
  }
}

.name {
  text-decoration: none;
}
