@import "@mapix/common/src/assets/stylesheets/colors";

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: $surface10;
  width: 750px;
  height: auto;
  padding: 32px;
  border-radius: 8px;
}

.title {
  margin-bottom: 24px;
}

.crossContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.crossIcon {
  width: 20px;
  height: 20px;
  filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
}

.input {
  width: 280px;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}

.currency {
  width: 144px;
  margin-right: 24px;
}

.buttons {
  margin-top: 48px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.button {
  height: 40px;
  margin-left: 20px;
  width: 184px;
}

.error {
  margin-top: 16px;
}
