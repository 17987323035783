@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.filterContainer {
  padding: 38px 38px 24px 40px;
  background-color: $surface10;
  width: 860px;

  @include mobile {
    padding: 19px 19px 12px 20px;
    width: 90%;
  }

  .cross {
    align-self: end;
    height: 24px;
    filter: invert(62%) sepia(5%) saturate(451%) hue-rotate(169deg) brightness(88%) contrast(90%);
  }

  .title {
    margin-bottom: 30px;
  }

  .subTitle {
    margin-bottom: 24px;
  }

  .checkboxContainer {
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 33%;
      margin-bottom: 16px;

      @include mobile {
        width: 50%;
      }
    }
  }

  .line {
    border-top: 1px solid $secondary70;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .buttonContainer {
    align-self: end;
    margin-top: 70px;

    .cancel {
      margin-right: 24px;
    }
  }
}
