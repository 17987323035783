@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  background-color: $surface20;

  @include mobile {
    min-height: $page-min-height;
  }
}

.menu {
  margin-bottom: 10px;
  margin-left: 15px;
}

.modalButtonWrapper {
  display: flex;
  justify-content: center;

  .modalButton {
    padding: 8px 16px;
    width: 184px;
    height: 40px;
  }
}

.modalText {
  margin-top: 24px;
}
