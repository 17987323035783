@import "@mapix/common/src/assets/stylesheets/colors";

.table {
  width: 100%;
  border-collapse: collapse;
}

.header {
  background: $primary10;

  .row {
    :first-child {
      border-top-left-radius: 8px;
    }

    :last-child {
      border-top-right-radius: 8px;
    }
  }

  .cell {
    background: $primary10;
    padding: 20px 4px 16px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;

    &.icons {
      width: 2%;
    }
  }
}

.bodyRow {
  border-bottom: 1px solid $secondary40;
  background-color: $surface10;

  &.clickable {
    cursor: pointer;
  }

  .cell {
    padding: 14px 4px;
    height: 48px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.cellSize {
      max-width: 8%;
      min-width: 5%;
    }

    &.capitalizeText {
      text-transform: capitalize;
    }

    .toRight {
      margin-left: auto;
    }

    &.iconCell {
      overflow: visible;
    }
  }
}
