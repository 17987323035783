@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $surface20;
  padding: 16px 32px;
  box-shadow: 0 4px 8px $surface20;
  border-radius: 8px;

  .currency {
    width: 144px;
    margin-right: 8px;
  }

  .closeButton {
    margin-left: auto;

    .closeIcon {
      width: 20px;
      height: 20px;
      filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
    }
  }

  .image {
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
    filter: invert(36%) sepia(86%) saturate(930%) hue-rotate(224deg) brightness(98%) contrast(88%);
  }

  .formContainer {
    margin-top: 36px;
    align-self: center;

    .buttonRow {
      display: flex;
      justify-content: center;

      .largeButton {
        padding: 8px 65px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .error {
      margin-top: 18px;
    }
  }
}
