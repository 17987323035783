@import "../../assets/stylesheets/mixins";
@import "../../assets/stylesheets/colors";

.breadcrumbContainer {
  display: flex;
  align-items: center;
  background-color: $secondary40;
  padding: 8px 0 8px 16px;

  @include mobile {
    background-color: $surface20;
  }

  .icon {
    height: 16px;
    width: 16px;
    margin: 0 14px;
    filter: invert(20%) sepia(0%) saturate(577%) hue-rotate(168deg) brightness(87%) contrast(77%);

    @include mobile {
      filter: invert(45%) sepia(6%) saturate(752%) hue-rotate(150deg) brightness(88%) contrast(83%);
      margin: 0 10px;
    }
  }

  .lastWeight {
    font-weight: 600;
  }

  button {
    padding: 0;
    line-height: 20px;
  }
}
