@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-image: url("../../assets/images/login.jpg");
  background-size: 100% 100%;
  height: 90vh;
}

.blue {
  color: $primary50;
}

.input {
  margin-top: 48px;
}

.inputEmail {
  margin-top: 16px;
}

.row {
  display: flex;
  flex-direction: row;
}

p {
  margin-top: 0;
  margin-right: 5px;
}

.button {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 36px;
}

.textNeedAccount {
  @extend p;

  margin-bottom: 0;
}

.buttonLink {
  @extend .blue;

  text-decoration: underline $primary40;
}

.checkboxContainer {
  display: flex;
  margin-top: 32px;
  width: 300px;
}

.googleContainer {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}
