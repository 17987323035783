@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.lighterText {
  font-weight: 300;
}

.datesWrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .disclaimer {
    max-width: 280px;
  }
}
