$primary10: #f5f3fa;
$primary20: #e5e0fb;
$primary30: #b2a1f2;
$primary40: #9882ee;
$primary50: #7e63ea;
$primary60: #7159d3;
$primary70: #654fbb;
$primary80: #4c3b8c;
$secondary10: #fcfdff;
$secondary20: #f7fafd;
$secondary30: #f3f6fb;
$secondary40: #e7ecf2;
$secondary50: #d7dde5;
$secondary60: #c2cad2;
$secondary70: #a8afb6;
$secondary80: #868c92;
$secondary90: #5d6267;
$secondary100: #333638;
$textHeading: #0f0f0f;
$textBody: #626d71;
$textNeutral10: #fff;
$textNeutral20: #c5c9cb;
$textNeutral30: #8a9497;
$textNeutral40: #626d71;
$textNeutral50: #3f3f3f;
$surface10: #fff;
$surface20: #f9f9fc;
$surface30: #f4f6f8;
$surface40: #eaeff4;
$surface50: #e6e9ec;
$tertiary10: #dcf5ef;
$tertiary40: #4ecfb0;
$tertiary50: #46ba9e;
$danger05: #ffdfe0;
$danger10: #f7c3c4;
$danger20: #ef8889;
$danger30: #ea6062;
$danger40: #e53e41;
$danger50: #b72d2f;
$gradientRight: #b09ff4;
$gradient50: $primary50,$gradientRight;
$warning10: #feeedf;
$warning40: #fbbd81;
$warning50: #e19b57;
$info10: #eff5fd;
$info40: #2460d7;
$info30: #6595f5;
$info50: #0e3fa1;
$success10: #c6ded3;
$success40: #40916c;
$success50: #337456;
$yellow50: #977940;
$yellow10: #fff4df;

// colors that are not in design system
$grey: #b9b9b9;
$blackD: #0000000d;
$cardShadow: #a8afb64d;
$creditCard: #ab7bff;
