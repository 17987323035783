@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  display: flex;
  width: 95%;
  margin-left: 40px;
  margin-top: 32px;
  flex-direction: column;
  min-height: calc(#{$min-height} - #{$footer-height} - #{$header-height} - 32px);

  @include mobile {
    margin-top: 0;
    margin-left: 16px;
    width: 95%;
  }
}

// in the next line, the size is hardcoded because the mobile screen heading is H1, and in desktop is H4.
.title {
  @include mobile {
    font-size: 32px;
  }
}

.closeIcon {
  margin-left: 6px;
  height: 18px;
  filter: invert(37%) sepia(14%) saturate(4934%) hue-rotate(223deg) brightness(96%) contrast(85%);
}

.button {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  height: 40px;

  @include mobile {
    display: none;
  }
}

.topContainer {
  display: flex;
  width: 100%;
}

.data {
  margin-top: 96px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;

  @include mobile {
    margin-top: 22px;
    flex-direction: column;
    margin-bottom: 22px;
  }
}

.column {
  display: flex;
  flex-direction: column;

  @include mobile {
    margin-top: 16px;
  }
}

.realData {
  margin-top: 16px;

  @include mobile {
    margin-top: 0;
  }
}

.buttonText {
  background-color: $surface10;
  color: $primary40;
  height: 40px;
  align-items: center;
  display: flex;
  text-decoration: none;
  padding: 8px 56px 8px 0;
  margin-right: 20px;

  &.editButton {
    padding-left: 0;
  }

  @include mobile {
    margin-bottom: 15px;
  }
}

.editIcon {
  height: 25px;
  margin-left: 6px;
  padding-bottom: 5px;
}

.buttons {
  margin-top: 96px;
  display: flex;

  @include mobile {
    flex-direction: column;
    margin-left: 0;
    margin-top: 0;
  }
}

.text {
  display: flex;
  width: 40%;

  @include mobile {
    margin-top: 16px;
    width: 100%;
  }
}

.menu {
  display: none;
  margin-left: 39px;

  @include mobile {
    display: flex;
    margin-left: 16px;
  }
}

.logoutIconSmall {
  height: 25px;
  margin-left: 6px;
  padding-bottom: 5px;
  filter: invert(32%) sepia(100%) saturate(696%) hue-rotate(220deg) brightness(112%) contrast(85%);
}

.logoutIcon {
  filter: invert(32%) sepia(100%) saturate(696%) hue-rotate(220deg) brightness(112%) contrast(85%);
  width: 56px;
  height: 56px;
}

.infoRequired {
  display: flex;
  margin-top: 8px;
  background-color: $warning10;
  width: 570px;
  padding: 8px 16px;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;

  @include mobile {
    width: 95%;
  }
}

.alertIcon {
  width: 24px;
  height: 24px;
  filter: invert(79%) sepia(47%) saturate(800%) hue-rotate(320deg) brightness(93%) contrast(89%);

  @include mobile {
    margin-right: 16px;
  }
}
