@import "@mapix/common/src/assets/stylesheets/colors";

.paginationContainer {
  display: flex;
  align-items: center;
}

.button {
  width: 39px;
  height: 32px;
  color: $primary40;

  &.selected {
    background: $primary50;
    border: 1px solid $primary60;
    box-shadow: 0 2px 4px $primary10;
    color: $textNeutral10;
    border-radius: 8px;
  }
}

.chevron {
  filter: invert(57%) sepia(5%) saturate(431%) hue-rotate(169deg) brightness(95%) contrast(90%);
  height: 20px;
  width: 20px;
  cursor: pointer;
}
