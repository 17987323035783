@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.list {
  display: none;

  @include mobile {
    display: block;
    display: flex;
    flex-direction: row;
  }
}

.icon {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  filter: invert(40%) sepia(20%) saturate(203%) hue-rotate(150deg) brightness(97%) contrast(86%);
}

.listElem {
  text-decoration: none;
}

.menuList {
  display: flex;
  margin-top: 8px;
}
