@import "../../assets/stylesheets/colors";

.progressBarWrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;

  .progressBar {
    display: flex;
    height: 6px;
    width: 100%;
    background-color: $surface50;
    border-radius: 5px;
    margin-right: 8px;

    .filler {
      height: 6px;
      background-color: $info30;
      border-radius: inherit;
    }
  }

  .updating {
    margin-right: 12px;
  }
}
