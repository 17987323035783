@import "@mapix/common/src/assets/stylesheets/colors";

.title {
  margin-top: 24px;
}

.topContainer {
  width: 100%;
  display: flex;
}

.topText {
  width: 80%;
}

.closeIcon {
  margin-left: 6px;
  height: 18px;
  filter: invert(37%) sepia(14%) saturate(4934%) hue-rotate(223deg) brightness(96%) contrast(85%);
}

.closeButton {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  width: 20%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;

  h4,
  h5 {
    font-weight: 300;
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

.input {
  width: 280px;

  &.large {
    width: 320px;
  }

  &.small {
    width: 144px;
  }
}

.formContainer {
  margin-top: 24px;

  > * {
    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .row {
    display: flex;
    align-items: center;

    > * {
      margin-right: 24px;
    }
  }
}

.formTitle {
  margin-top: 56px;
}

.buttonRow {
  background-color: $surface10;

  .button {
    align-self: end;
  }
}

.checkIcon {
  margin-left: 10px;
}

.circleCloseIcon {
  filter: invert(56%) sepia(87%) saturate(5094%) hue-rotate(232deg) brightness(98%) contrast(87%);
}

.form {
  position: relative;
  padding-bottom: 96px;
}
