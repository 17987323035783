@import "@mapix/common/src/assets/stylesheets/base-styles";
@import "@mapix/common/src/assets/stylesheets/global-styles.module";

.fullWidth {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-justify-center {
  align-items: center;
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.wrap {
  flex-wrap: wrap;
}

.bold {
  font-weight: bold;
}
