@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/colors";

.container {
  display: flex;
  align-items: center;
  height: 36px;
  background-color: $danger10;
  padding: 8px 0 8px 16px;
  border-radius: 4px;
  position: absolute;
  left: $drawer-width;
  top: 136px;
  z-index: $z-index-error-message;
}

p {
  margin: 0;
  padding: 0;
}

.icon {
  margin-right: 16px;
  filter: invert(22%) sepia(60%) saturate(2664%) hue-rotate(339deg) brightness(91%) contrast(92%);
}

.close {
  filter: invert(22%) sepia(60%) saturate(2664%) hue-rotate(339deg) brightness(91%) contrast(92%);
}
