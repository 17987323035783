@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.containerStyles {
  height: 8px;
  width: 98%;
  background-color: $secondary50;
  border-radius: 4px;
  display: flex;
}

.fillerStyles {
  background-color: $tertiary50;
  border-radius: inherit;
}

.statusContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  @include mobile {
    margin-top: 4px;
  }
}

.containerProgressBar {
  display: flex;
  flex-direction: column;
}

.progressBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkIcon {
  width: 24px;
  margin-left: 8px;
}

.chevronIcon {
  height: 24px;
  width: 24px;
  filter: invert(60%) sepia(12%) saturate(195%) hue-rotate(169deg) brightness(90%) contrast(88%);
  cursor: pointer;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  display: flex;
  flex-direction: row;
}
