@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/colors";

.container {
  position: absolute;
  z-index: $z-index-tooltip;
  background-color: $secondary100;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  top: -65px;
  right: -80px;

  ::before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    left: 50%;
    bottom: 6px;
    border: 8px solid transparent;
    border-bottom: 0;
    border-top: 8px solid $secondary100;
    transform: translate(-50%, calc(100% + 5px));
  }
}
