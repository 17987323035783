@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mobile {
    padding: 8px 16px;
  }
}

.column {
  width: 100%;
}

.icon {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-left: 32px;
}

.iconNew {
  @extend .icon;

  width: 81px;
  background-color: $warning10;
  color: $warning50;
}

.iconInProgress {
  @extend .icon;

  width: 126px;
  background-color: $info10;
  color: $info50;
}

.iconSolved {
  @extend .icon;

  width: 95px;
  background-color: $info10;
  color: $info50;
}

.iconRejected {
  @extend .icon;

  width: 109px;
  background-color: $danger10;
  color: $danger50;
}

.row {
  display: flex;
  flex-direction: row;
}

.margin {
  margin-top: 20px;
}

.marginEvent {
  margin-top: 10px;

  @include mobile {
    margin-top: 4px;
  }
}

.table {
  width: 100%;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
}

.photos {
  display: flex;
  width: 90%;
  flex-wrap: wrap;

  @include mobile {
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
  }
}

.photo {
  width: 210px;
  height: 210px;
  margin: 10px 20px 10px 0;

  @include mobile {
    width: 140px;
    height: 140px;
  }
}

.bold {
  font-weight: bold;
}

.button {
  color: $surface10;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.buttonRed {
  @extend .button;

  color: $danger50;
}

.buttons {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 30px;
}

.closeIcon {
  filter: invert(20%) sepia(87%) saturate(2204%) hue-rotate(341deg) brightness(92%) contrast(89%);
  margin-left: 5px;
}

.dollarIcon {
  margin-left: 5px;
  filter: invert(99%) sepia(1%) saturate(2109%) hue-rotate(229deg) brightness(119%) contrast(100%);
}

.buttonsTop {
  @extend .row;

  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.iconEdit {
  margin-left: 5px;
  width: 24px;
  height: 24px;
}

.iconPrimary {
  @extend .iconEdit;

  filter: invert(50%) sepia(61%) saturate(488%) hue-rotate(211deg) brightness(101%) contrast(87%);
}

.buttonText {
  margin-left: 51px;

  .center {
    display: flex;
    align-items: center;
  }
}

.italic {
  margin-top: 20px;
  font-style: italic;

  @include mobile {
    margin-top: 16px;
  }
}

.leftText {
  width: 50%;
}

.select {
  margin-top: 24px;
  width: 320px;
}

.checkIcon {
  margin-left: 8px;
}

.iconBlue {
  width: 44px;
  height: 44px;
  filter: invert(39%) sepia(82%) saturate(528%) hue-rotate(211deg) brightness(87%) contrast(91%);
}

.iconRight {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.title {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  width: 60%;

  @include mobile {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }
}

.bottomButton {
  margin-top: 48px;
  display: flex;
  width: 100%;
  justify-content: center;

  .button {
    width: 184px;
    height: 40px;
  }
}
