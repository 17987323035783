@import "@mapix/common/src/assets/stylesheets/colors";

$formLeftMargin: 16px;

.textContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.form {
  height: 90px;
  padding: 8px 16px 40px;
  width: 100%;
  background-color: $surface10;
  border: none;
  box-shadow: inset 0 -1px 0 $primary30;
  border-radius: 8px 8px 0 0;

  &:active {
    border: none;
    background: $secondary20;
    border-bottom: 1px solid $primary70;
  }

  &:disabled {
    background-color: $surface50;
  }
}

.formError {
  @extend .form;

  border-bottom: 1px solid $danger50;
  box-shadow: inset 0 -1px 0 $danger50;

  &:active {
    background: $secondary20;
  }
}

.formLabel {
  position: absolute;
  top: 0;
  left: $formLeftMargin;
}

.helperLabel {
  margin-left: $formLeftMargin;
  margin-top: 8px;
}

.maxLength {
  position: absolute;
  bottom: 0;
  margin-bottom: -16px;
  right: 0;
}

.disabled {
  height: 48px;
  width: 100%;
  border: none;
  box-shadow: inset 0 -1px 0 $primary30;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid $primary50;
  padding: 8px $formLeftMargin;
  background-color: $surface50;
}
