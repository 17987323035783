@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.erorrMessageContainer {
  min-height: $page-min-height;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .dashContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 40px 16px;

    .buildingInfo {
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .panelContainer {
      display: flex;
    }

    .task {
      background-color: $primary10;
    }

    .inProgressTask {
      background-color: $info10;
    }

    .overdue {
      background: $yellow10;
    }

    .occupancy {
      background: $tertiary10;
    }

    .cardsContainer > div {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .tabContainer {
      display: flex;
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }

  .buttonRow {
    display: flex;
    justify-content: right;
    margin-left: auto;

    > * {
      margin-right: 20px;
    }

    .button {
      display: flex;
      align-items: center;
    }

    .icon {
      height: 20px;
      width: 20px;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);
      margin-left: 10px;
    }
  }

  .contentTitle {
    margin-top: 16px;
    font-weight: bold;
  }

  .line {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    border: 1px solid $secondary50;
  }
}
