@import "../../assets/stylesheets/mixins";
@import "../../assets/stylesheets/colors";

.spinner {
  height: 75px;

  &Fixed {
    @extend .spinner;

    position: fixed;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &Small {
    @extend .spinner;

    height: 30px;
    width: 30px;
    align-self: center;
    justify-self: center;
  }
}
