@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  background-color: $surface10;

  @include mobile {
    width: 352px;
    padding: 24px 16px;
  }
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeIcon {
  height: 24px;
  width: 24px;
  filter: invert(22%) sepia(1%) saturate(2291%) hue-rotate(60deg) brightness(101%) contrast(92%);
}

.subtitle {
  margin-top: 24px;
  font-weight: bold;

  @include mobile {
    margin-top: 8px;
  }
}

.data {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @include mobile {
    margin-top: 0;
  }
}

.input {
  width: 320px;
}

.column {
  display: flex;
  flex-direction: column;

  @include mobile {
    margin-top: 24px;
  }
}

.secondColumn {
  margin-left: 40px;

  @include mobile {
    margin-left: 0;
  }
}

.buttonSave {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  width: 210px;
  height: 40px;

  @include mobile {
    margin-top: 40px;
  }
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.radioMargin {
  margin-left: 32px;
  margin-right: 13px;

  &:first-child {
    margin-left: 0;
  }
}

.radio {
  display: flex;
  margin-top: 40px;
}

.secondRow {
  margin-top: 40px;
}
