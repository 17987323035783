@import "../../assets/stylesheets/colors";

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .input {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1.5px solid $secondary90;
    transition: 0.2s all linear;
    margin: 0;

    &Checked {
      @extend .input;

      border-color: $primary50;
    }

    &Disabled {
      @extend .input;

      border-color: $secondary40;
    }

    .innerCircle {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      transition: 0.2s all linear;

      &Checked {
        @extend .innerCircle;

        background-color: $primary50;
      }
    }
  }

  .radio {
    display: none;
  }
}
