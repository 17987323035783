@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.inputContainer {
  position: relative;

  .inputWidth {
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    max-height: 180px;
    z-index: $z-index-location-select;
    background: $surface10;
    box-shadow: 0 2px 19px 1px $surface50;
    border-radius: 0 0 8px 8px;
    padding: 4px 8px;
    overflow: scroll;

    @include mobile {
      max-height: 120px;
    }

    .retry {
      margin-top: 10px;
    }

    .item {
      text-align: left;
      padding: 10px 8px;
      border-bottom: 1px solid $secondary50;

      &Center {
        @extend .item;

        text-align: center;
        font-weight: 300;
      }

      &:hover {
        background-color: $secondary20;
      }

      &:active {
        background-color: $secondary30;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.spinner {
  align-self: center;
  justify-self: center;
}

.searchIcon {
  width: 20px;
  height: 20px;
  filter: invert(60%) sepia(6%) saturate(386%) hue-rotate(169deg) brightness(90%) contrast(85%);
}
