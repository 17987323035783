@import "../../assets/stylesheets/colors";

.avatar {
  flex-shrink: 0;
  background: $secondary60;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-decoration: none;
  object-fit: contain;
  object-position: center;

  .image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }

  &Small {
    @extend .avatar;

    width: 42px;
    height: 42px;

    .initials {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &Medium {
    @extend .avatar;

    width: 110px;
    height: 110px;

    .initials {
      font-size: 40px;
      line-height: 60px;
    }
  }

  &Large {
    @extend .avatar;

    width: 296px;
    height: 296px;

    .initials {
      font-size: 112px;
      line-height: 160px;
    }
  }
}
