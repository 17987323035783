@import "assets/stylesheets/colors";

.container {
  display: flex;
  margin-top: 28px;

  &.margin {
    margin-top: 40px;
  }
}

.itemBlock {
  display: flex;
  flex-direction: column;
  width: 50%;

  &.firstRow {
    width: 33.3%;
  }

  .photoTitle {
    margin-bottom: 24px;
  }

  &.photos {
    width: 100%;
  }

  > * {
    margin-bottom: 8px;
  }

  .strike {
    text-decoration: underline;
  }
}

.buttonRow {
  display: flex;
  justify-content: right;

  .button {
    display: flex;
    align-items: center;
  }

  .icon {
    height: 20px;
    width: 20px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);
    margin-left: 10px;
  }
}

.italic {
  margin-top: 20px;
  font-style: italic;
}

.servicesContainer {
  width: 900px;
  display: flex;
  flex-wrap: wrap;

  .serviceItem {
    display: flex;
    align-items: center;
    width: 350px;
    margin-top: 8px;
  }

  .check {
    height: 25px;
    width: 25px;
    margin-right: 8px;
  }
}

.photoRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > * {
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }

  .img {
    width: 210px;
    height: 210px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
  }
}
