@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.photoRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  white-space: wrap;

  @include mobile {
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
  }

  > * {
    margin-right: 20px;

    :last-child {
      margin-right: 0;
    }
  }
}

.inputLabel {
  align-self: flex-start;
  margin: 24px 0;
  cursor: pointer;
}

.icon {
  height: 24px;
  width: 24px;
  filter: invert(41%) sepia(82%) saturate(2435%) hue-rotate(230deg) brightness(85%) contrast(95%);
}

.input {
  opacity: 0;
  width: 10px;
}

.imagePlaceholder {
  width: 210px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondary40;

  .img {
    width: 42px;
    height: 42px;
    filter: invert(29%) sepia(100%) saturate(958%) hue-rotate(228deg) brightness(86%) contrast(85%);
  }
}

.uploadedImageContainer {
  position: relative;
  margin-top: 25px;
  width: 210px;
  height: 210px;

  .image {
    object-fit: contain;
    object-position: top;
    width: 100%;
    height: 100%;

    @include mobile {
      width: 140px;
      height: 140px;
    }
  }

  .buttonCross {
    position: absolute;
    right: 4px;
    top: 8px;
  }
}

.cross {
  height: 24px;
  width: 24px;

  &.pdf {
    margin-left: 10px;
  }
}

.bold {
  font-weight: bold;
}

.row {
  display: flex;
  align-items: center;
}

.alertIcon {
  height: 24px;
  width: 24px;
  margin-left: 10px;
}

.iconDisabled {
  height: 24px;
  width: 24px;
  filter: invert(96%) sepia(5%) saturate(148%) hue-rotate(156deg) brightness(86%) contrast(85%);
}
