@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "assets/stylesheets/variables";

.datesWrapper {
  display: flex;
  margin-bottom: 40px;

  .infoIcon {
    height: 24px;
    width: 24px;
    margin-left: 24px;
  }

  > * {
    margin-right: 24px;
  }
}

.row {
  display: flex;
  align-items: center;

  > * {
    margin-right: 24px;
  }
}

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;

  .addressSubtitle {
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .subtitle {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .alertIcon {
    display: flex;
    margin-right: 34px;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .errorContainer {
    width: 28px;
    height: 24px;
    filter: invert(52%) sepia(79%) saturate(663%) hue-rotate(315deg) brightness(89%) contrast(107%);
  }

  .renewButton {
    width: 158px;
    height: 48px;
    margin-right: 40px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $surface10;
    padding-bottom: 8px;
    padding-top: 8px;
    display: flex;
    justify-content: right;
  }

  .checkboxContainer {
    width: 700px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .checkbox {
      width: 200px;
      text-transform: capitalize;
    }

    > * {
      margin-bottom: 24px;
    }
  }

  .services {
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .endDate {
    margin-bottom: 40px;
  }
}

.endModal {
  width: 56px;
  height: 56px;
  filter: invert(44%) sepia(25%) saturate(1355%) hue-rotate(212deg) brightness(96%) contrast(103%);
}

.currency {
  width: 144px;
}

.options {
  text-transform: capitalize;
}

.select {
  input {
    text-transform: capitalize;
  }
}

.input {
  width: 280px;

  &.small {
    width: 144px;
  }
}

.dueDate {
  display: flex;
  width: 120px;
  margin-left: 24px;
}

.otherOption {
  width: 60px;
}
