@import "@mapix/common/src/assets/stylesheets/colors";

.cardContainer {
  display: flex;
  padding: 14px 16px 18px;
  box-shadow: 0 2px 19px 1px $surface50;
  border-radius: 8px;

  .number {
    border-radius: 100%;
    margin-right: 16px;
    height: 49px;
    width: 49px;

    .date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .occupancy {
    height: 49px;
    width: 49px;
    margin-right: 16px;

    .occupancyLabel {
      font-size: 16px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
  }
}
