@import "@mapix/common/src/assets/stylesheets/colors";

.contractorContainer {
  padding: 38px 38px 24px 40px;
  background-color: $surface10;
  width: 664px;

  .cross {
    align-self: end;
    height: 24px;
    filter: invert(62%) sepia(5%) saturate(451%) hue-rotate(169deg) brightness(88%) contrast(90%);
  }

  .subtitleMargin {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .input {
    width: 280px;
  }

  .firstRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .buttonContainer {
    align-self: end;
    margin-top: 70px;
    margin-bottom: 24px;

    .cancel {
      margin-right: 24px;
    }

    .buttonWidth {
      width: 184px;
    }
  }

  .errorContainer {
    display: flex;
    align-items: center;
    height: 36px;
    background-color: $danger10;
    border-radius: 4px;
    width: 466px;
  }

  .icon {
    margin-right: 16px;
    filter: invert(22%) sepia(60%) saturate(2664%) hue-rotate(339deg) brightness(91%) contrast(92%);
  }

  .close {
    filter: invert(22%) sepia(60%) saturate(2664%) hue-rotate(339deg) brightness(91%) contrast(92%);
  }
}
