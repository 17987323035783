@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 16px;
  width: 100%;
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 16px;
}

.tabContainer {
  margin-right: 20px;
}

.input {
  width: 310px;

  input::placeholder {
    font-size: 12px;
  }
}

.table {
  margin-top: 16px;
}

.icons {
  width: 24px;
  height: 24px;
  filter: invert(36%) sepia(7%) saturate(430%) hue-rotate(169deg) brightness(98%) contrast(86%);
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-right: 54px;
}

.overdue {
  color: $warning50;
}

.tableRightElements {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
}

.link {
  text-decoration: none;
}

.details {
  text-decoration: $secondary90 underline;
}

.filterRow {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;

  .input {
    width: 310px;
    margin-right: 20px;

    input::placeholder {
      font-size: 12px;
    }
  }

  .filterIcon {
    margin-left: 6px;
    height: 18px;
    width: 18px;
    filter: invert(33%) sepia(95%) saturate(1164%) hue-rotate(230deg) brightness(89%) contrast(84%);
  }

  .dateFilter {
    width: 230px;
    margin-left: auto;
    margin-right: 20px;
  }

  .plusIcon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    filter: invert(99%) sepia(9%) saturate(248%) hue-rotate(334deg) brightness(119%) contrast(100%);
  }
}

.emptyState {
  display: flex;
  width: 100%;
  margin-top: 160px;
}

.cardIcon {
  filter: invert(34%) sepia(9%) saturate(6330%) hue-rotate(221deg) brightness(95%) contrast(89%);
}

.spinner {
  margin-top: 200px;
  display: flex;
  justify-content: center;
}

.iconDelete {
  width: 56px;
  height: 56px;
  filter: invert(42%) sepia(48%) saturate(2002%) hue-rotate(227deg) brightness(92%) contrast(100%);
}

.billedAmount {
  position: relative;
  display: flex;
  align-items: center;

  .info {
    margin-left: 8px;
    filter: invert(89%) sepia(8%) saturate(241%) hue-rotate(169deg) brightness(91%) contrast(91%);
  }
}

.modalButtonWrapper {
  display: flex;
  justify-content: center;

  .modalButton {
    padding: 8px 16px;
    width: 184px;
    height: 40px;
  }
}

.modalText {
  margin-top: 24px;
  text-align: center;
}
