@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;

  .addressSubtitle {
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .assignAction {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .alertEmailIcon {
    width: 15px;
    margin-left: 35px;
    margin-right: 5px;
  }

  .alertEmail {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .alertIcon {
    display: flex;
    margin-right: 34px;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .errorContainer {
    width: 28px;
    height: 24px;
    filter: invert(52%) sepia(79%) saturate(663%) hue-rotate(315deg) brightness(89%) contrast(107%);
  }

  .endButton {
    width: 130px;
    height: 48px;
    margin-right: 40px;

    &.endAndArchive {
      width: auto;
    }
  }

  .input {
    width: 280px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $surface10;
    padding-bottom: 8px;
    padding-top: 8px;
    display: flex;
    justify-content: right;
    justify-content: column;
  }
}

.endModal {
  width: 56px;
  height: 56px;
  filter: invert(44%) sepia(25%) saturate(1355%) hue-rotate(212deg) brightness(96%) contrast(103%);
}
