@import "../../assets/stylesheets/mixins";
@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/colors";

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @include mobile {
    background-color: $surface20;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .grid {
      display: grid;
      padding-left: 40px;
      padding-right: 20px;
      grid-gap: 44px 20px;
      grid-template-columns: minmax(300px, 530px) minmax(300px, 530px);
      width: 100%;

      @include mobile {
        grid-template-columns: 1fr;
        padding: 0 18px 0 14px;
      }
    }

    .flex {
      display: flex;
      flex-direction: column;
    }

    .title {
      margin-left: 34px;
      margin-top: 60px;

      @include mobile {
        margin: 0 0 0 16px;
      }
    }

    .subtitle {
      margin-left: 34px;
      margin-top: 12px;
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 0;
      }
    }
  }

  .footerWrapper {
    width: 100%;
    flex-direction: column;
    display: flex;
    padding: 0 28px 52px 50px;
    margin-top: 90px;

    @include mobile {
      margin-top: 0;
      padding: 12px 18px 0 14px;
    }

    @include mobile {
      margin-top: 40px;
      padding: 0 19px 47px;
    }

    .divider {
      border: 1px solid $secondary40;
      width: 100%;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 28px;

      @include mobile {
        padding-top: 0;
      }

      .back {
        display: flex;
        flex-direction: row;
        align-items: center;

        .chevronLeft {
          width: 20px;
          height: 20px;
          filter: invert(57%) sepia(8%) saturate(3868%) hue-rotate(210deg) brightness(99%) contrast(89%);
          margin-right: 5px;
        }
      }

      .save {
        width: 264px;
        height: 48px;

        @include mobile {
          width: 99%;
          height: 48px;
        }
      }
    }
  }
}
