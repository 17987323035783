@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.stepperContainer {
  display: flex;
  margin-left: auto;

  .stepContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .check {
      height: 18px;
      width: 18px;

      @include mobile {
        width: 12px;
        height: 12px;
      }
    }

    .bubble {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 8px;

      @include mobile {
        width: 24px;
        height: 24px;
        margin-right: 0;
      }

      &.active {
        background-color: $primary50;
      }

      &.completed {
        background-color: $primary70;
      }

      &.next {
        background-color: $secondary60;
      }
    }

    .line {
      width: 56px;
      margin-left: 8px;
      margin-right: 8px;

      @include mobile {
        width: 24px;
      }

      &.active {
        border-bottom: 1px dashed $primary50;
      }

      &.completed {
        border-bottom: 1px solid $primary70;
      }

      &.next {
        border-bottom: 1px dashed $secondary70;

        @include mobile {
          border-bottom: 1px dashed $secondary60;
        }
      }
    }
  }
}
