@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  background-image: url("../../assets/images/login.jpg");
  background-size: cover;
  justify-content: center;
  align-items: center;
  min-height: calc(#{$min-height} - #{$footer-height} - #{$header-height});
}

.buttonLogOut {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 470px;
  background-color: $surface10;
}
