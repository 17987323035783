@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/variables";

.inputDate {
  position: relative;
  width: 100%;

  .calendarContent {
    flex-direction: row;
    justify-content: center;
    position: absolute;
    z-index: $z-index-calendar;
    padding: 4px 8px;
    background-color: $surface10;
    max-height: 350px;
    overflow: auto;
    box-shadow: 0 2px 19px 1px $surface50;
    border-radius: 0 0 8px 8px;
    width: 350px;

    &FromTop {
      @extend .calendarContent;

      bottom: 100%;
    }
  }
}
