@import "@mapix/common/src/assets/stylesheets/colors";

.taskViewContainer {
  display: flex;
  flex-direction: column;

  .photos {
    display: flex;
    width: 90%;
    flex-wrap: wrap;

    .photoRow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      > * {
        margin-right: 20px;

        :last-child {
          margin-right: 0;
        }
      }

      .img {
        cursor: pointer;
        width: 210px;
        height: 180px;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
