@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.taskStatus {
  margin: 16px 0 8px;
}

.container {
  width: 310px;

  @include mobile {
    width: 100%;
  }
}

.whiteBox {
  padding: 14px 16px;
  background: $surface10;
  border-radius: 8px 8px 0 0;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
}
