@import "../../assets/stylesheets/mixins";
@import "../../assets/stylesheets/colors";

.container {
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 99%;
  }
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 40px;

  @include mobile {
    margin-top: 4px;
    margin-bottom: 34px;
    width: 90%;
  }
}

.content {
  width: 70%;

  @include mobile {
    width: 95%;
  }
}

.item {
  margin-top: 28px;

  &:first-child {
    margin-top: 0;
  }
}

.allRow {
  grid-column: 1/-1;
  margin-bottom: -16px;
}

.cardContainer {
  @extend .allRow;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.browseFilesWrapper {
  background-color: $surface10;
  padding: 20px 12px 16px;
  border-radius: 8px;
}

.sameAsLegalInfo {
  margin: 0 0 24px 40px;
  padding-left: 28px;

  @include mobile {
    margin-left: 16px;
  }
}

.sameAsLegalInfoLabel {
  line-height: 16px;
}
