@import "assets/stylesheets/colors";
@import "assets/stylesheets/variables";
@import "assets/stylesheets/mixins";

.input {
  width: 280px;

  &.description {
    width: 710px;
  }
}

.checkboxContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  align-content: center;

  .checkbox {
    margin-right: 56px;
    align-items: center;
  }

  > * {
    margin-bottom: 24px;
  }
}

.margin {
  margin-top: 72px;
  margin-bottom: 24px;
}

.firstMargin {
  margin-top: 24px;
  margin-bottom: 24px;
}

.plusIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  filter: invert(38%) sepia(82%) saturate(595%) hue-rotate(214deg) brightness(87%) contrast(95%);
}

.contractorButton {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskButton {
  margin-left: 16px;
}

.iconCellLeftClass {
  min-width: 1%;
}

.paginationContainer {
  margin-top: 24px;
  margin-right: 54px;
  display: flex;
  justify-content: flex-end;
}

.emptyState {
  min-width: 20%;
}
