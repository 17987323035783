@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 394px;
  background-color: $surface20;
  border-radius: 8px;
  padding: 38px 24px 24px;

  &SpinnerSafecharge {
    opacity: 0;
  }

  @include mobile {
    width: 100%;
    padding: 16px;
  }

  .titleContainer {
    display: flex;
    margin-bottom: 24px;

    .close {
      height: 24px;
      width: 24px;
      cursor: pointer;
      margin-left: auto;
    }
  }

  .spinnerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
}
