@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;
}

.input {
  width: 280px;

  &.description {
    width: 710px;
  }

  &.large {
    width: 320px;
  }
}

.checkboxContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  align-content: center;

  .checkbox {
    margin-right: 56px;
    text-transform: capitalize;
    align-items: center;
  }

  > * {
    margin-bottom: 24px;
  }
}

.firstMargin {
  margin-top: 24px;
  margin-bottom: 24px;
}

.area {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.margin {
  margin-top: 72px;
  margin-bottom: 24px;
}

.addressSubtitle {
  margin-top: 8px;
}

.form {
  display: flex;
  align-items: center;
}
