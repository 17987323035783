@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 40px 16px;
  min-height: $min-height;

  @include mobile {
    width: 100%;
    padding: 0 16px;
    margin-top: 24px;
    border-radius: 40px;
  }
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 16px;
}

.showingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 420px;

  @include mobile {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.showing {
  @include mobile {
    margin-top: 24px;
    font-weight: bold;
  }
}

.data {
  margin-top: 75px;
  width: 100%;

  @include mobile {
    margin-top: 16px;
  }
}

.select {
  margin-left: 16px;

  @include mobile {
    margin-top: 8px;
    margin-left: 0;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.card {
  margin-bottom: 32px;

  .column {
    width: 30%;

    @include mobile {
      width: 50%;
    }
  }

  @include mobile {
    margin-bottom: 24px;
  }

  .title {
    margin-bottom: 16px;
    font-weight: bold;

    @include mobile {
      margin-bottom: 0;
    }
  }

  .subtitle {
    margin-bottom: 8px;

    @include mobile {
      margin-bottom: 4px;
    }
  }

  .rowContent {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @include mobile {
      justify-content: space-between;
    }
  }

  .margin {
    margin-top: 16px;
  }
}

.divider {
  margin-bottom: 32px;
  color: $secondary50;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.underlined {
  text-decoration: underline;
}

.check {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.services {
  display: flex;
  flex-flow: row wrap;
  width: 50%;

  @include mobile {
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 310px;
    margin-right: 90px;
    margin-top: 8px;

    @include mobile {
      width: 120px;
      margin-right: 40px;
      margin-top: 4px;
    }
  }
}

.buttonLease {
  text-decoration: none;
  margin-top: 24px;
}

.menu {
  display: none;

  @include mobile {
    display: flex;
    margin-bottom: 16px;
  }
}
