@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .addressSubtitle {
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .subtitle {
    margin-bottom: 24px;
  }

  .input {
    width: 320px;
  }

  .row {
    display: flex;
    justify-content: row;
  }

  .marginRight {
    margin-right: 30px;
  }

  .secondRow {
    margin-top: 56px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }

  .button {
    height: 48px;
    width: 98px;
  }
}

.errorMessage {
  top: 50px;
  left: 30px;
}
