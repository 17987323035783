@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.iconContainer {
  position: relative;
  display: inline-block;

  .content {
    position: absolute;
    background: $surface10;
    box-shadow: 0 2px 19px 1px $surface50;
    border-radius: 8px;
    z-index: $z-index-more-icon;
    display: flex;
    flex-direction: column;
    right: 0;

    .item {
      text-align: left;
      min-width: 137px;
      padding: 10px 8px;
      border-bottom: 1px solid $secondary50;
    }
  }
}
