@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  background-image: url("../../assets/images/login.jpg");
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 400px;
  background-color: $surface10;
  border: 1px solid $secondary50;
  border-radius: 8px;
}

.blue {
  color: $primary50;
}

.buttonLink {
  @extend .blue;

  text-decoration: underline $primary40;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.button {
  margin-top: 40px;
}

.helperPassword {
  margin-top: 20px;
}

.input {
  margin-top: 48px;
}

.subtitle {
  margin-top: 24px;
  margin-bottom: 16px;
}

.spinner {
  min-height: $page-min-height;
  display: flex;
  justify-content: center;
  align-items: center;
}
