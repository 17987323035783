@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 16px;
  min-height: $min-height;
  width: 100%;

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.title {
  margin-bottom: 8px;

  @include mobile {
    margin-bottom: 24px;
  }
}

.cardInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.showingContainer {
  display: flex;
  margin-top: 16px;
  align-items: center;

  @include mobile {
    align-items: start;
    font-weight: bold;
    margin-top: 24px;
    flex-direction: column;
  }

  .select {
    width: 342px;
    margin-left: 16px;

    @include mobile {
      margin-top: 8px;
      margin-left: 0;
    }
  }
}

.paymentCardContainer {
  margin-top: 24px;
}

.filterContainer {
  margin: 16px 0 0 auto;

  @include mobile {
    margin: 24px 0 0;
  }

  .filter {
    width: 250px;

    @include mobile {
      width: 172px;
    }
  }
}

.paymentCard {
  background: $surface10;
  width: 100%;
  box-shadow: 0 2px 19px 1px $surface50;
  border-radius: 8px;
  padding: 24px 40px;

  @include mobile {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  .iconContainer {
    align-items: start;

    .icon {
      height: 24px;
      width: 24px;
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;

    .icon {
      height: 40px;
      width: 40px;
    }

    @include mobile {
      justify-content: space-between;
    }
  }
}

.menu {
  margin-bottom: 24px;
}

.emptyState {
  display: flex;
  width: 100%;
  margin-top: 160px;
  text-align: center;
}

.paginationContainer {
  align-self: end;
  margin-top: 40px;
}

.payNowButton {
  margin-left: 15px;
}

.userIcon {
  height: 16px;
  margin-left: 11px;
}

.modalButtonWrapper {
  display: flex;
  justify-content: center;

  .modalButton {
    padding: 8px 16px;
    width: 184px;
    height: 40px;
  }
}

.modalText {
  margin-top: 24px;
  text-align: center;
}
