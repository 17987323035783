@import "@mapix/common/src/assets/stylesheets/colors";
@import "assets/stylesheets/variables";

$inputWidth: 310px;
$formLeftMargin: 16px;

.inputContainer {
  position: relative;

  .inputWidth {
    width: $inputWidth;
  }

  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: $inputWidth;
    max-height: 300px;
    overflow: auto;
    z-index: $z-index-unit-numbers;
    background: $surface10;
    box-shadow: 0 2px 19px 1px $surface50;
    border-radius: 0 0 8px 8px;
    padding: 4px 8px;

    .item {
      text-align: left;
      padding: 10px 8px;
      border-bottom: 1px solid $secondary50;

      &:hover {
        background-color: $secondary20;
      }

      &:active {
        background-color: $secondary30;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
