.radioButtonContainer {
  display: flex;
  flex-direction: row;

  > label {
    margin-right: 24px;
  }

  .radioLabel {
    margin-left: 12px;
  }
}

.question {
  margin-bottom: 16px;
}
