@import "assets/stylesheets/colors";

.dashboardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterRow {
  margin-top: 24px;
  margin-bottom: 16px;
}

.input {
  width: 310px;
  margin-right: 20px;

  input::placeholder {
    font-size: 12px;
  }
}

.filterIcon {
  margin-left: 6px;
  width: 18px;
  height: 18px;
  filter: invert(33%) sepia(95%) saturate(1164%) hue-rotate(230deg) brightness(89%) contrast(84%);
}

.addContractorButton {
  margin-left: 20px;
}

.plusIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  filter: invert(99%) sepia(9%) saturate(248%) hue-rotate(334deg) brightness(119%) contrast(100%);
}

.spinner {
  margin-top: 200px;
  justify-content: center;
  display: flex;
}

.paginationContainer {
  align-self: end;
  margin-top: 24px;
  margin-right: 54px;
}

.icons {
  height: 24px;
  width: 24px;
  max-width: 24px;
  filter: invert(36%) sepia(7%) saturate(430%) hue-rotate(169deg) brightness(98%) contrast(86%);
}

.emptyState {
  display: flex;
  width: 100%;
  margin-top: 160px;
}

.userIcon {
  width: 48px;
  height: 48px;
  filter: invert(33%) sepia(95%) saturate(1164%) hue-rotate(230deg) brightness(89%) contrast(84%);
}

.buttonsRight {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.iconDelete {
  width: 56px;
  height: 56px;
  filter: invert(42%) sepia(48%) saturate(2002%) hue-rotate(227deg) brightness(92%) contrast(100%);
}
