@import "@mapix/common/src/assets/stylesheets/colors";
@import "assets/stylesheets/variables";

.filterContainer {
  position: relative;
  margin-left: 20px;
  min-width: 170px;

  .buttonContainer {
    padding: 8px;
    background-color: $surface20;
    border-radius: 8px;
    box-shadow: 0 2px 4px $surface10;
  }

  .number {
    border-radius: 100%;
    background-color: $primary20;
    margin-right: 8px;
    padding: 4px 8px;
  }

  .chevron {
    margin-left: 12px;
    height: 16px;
    width: 16px;
    filter: invert(36%) sepia(97%) saturate(1977%) hue-rotate(233deg) brightness(87%) contrast(87%);
  }

  .content {
    position: absolute;
    padding: 12px 16px 20px;
    background-color: $surface20;
    box-shadow: 0 2px 4px $secondary10;
    border-radius: 8px;
    z-index: $filter-applied-z-index;
    min-width: 600px;
    max-width: 600px;
  }

  .chipContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .chip {
    display: flex;
    padding: 10px 12px;
    background-color: $primary20;
    border-radius: 8px;
    margin-right: 8px;
    width: 32%;
    justify-content: space-between;
    margin-bottom: 10px;

    &:nth-child(3) {
      margin-right: 0;
    }
  }

  .cross {
    filter: invert(24%) sepia(59%) saturate(857%) hue-rotate(216deg) brightness(94%) contrast(94%);
    height: 18px;
    width: 18px;
  }

  .clear {
    margin-left: 8px;
    margin-top: 8px;
  }
}
