@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;

  .addressSubtitle {
    margin-top: 8px;
    margin-bottom: 60px;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $surface10;
    padding-bottom: 8px;
    padding-top: 8px;
    display: flex;
    justify-content: right;
  }

  .saveButton {
    height: 48px;
    margin-right: 40px;
  }
}
