@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/mixins";

.pageContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - $header-height);
  padding: 10px;

  @include mobile {
    flex-direction: column;
  }
}

.errorInformationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.errorDescription {
  text-align: center;
  margin-bottom: 32px;

  @include mobile {
    line-height: 24px;
  }
}

.errorName {
  font-weight: 500;
  margin-bottom: 48px;
}

.searchNoResults {
  height: 312px;
  width: 312px;

  @include mobile {
    height: 142px;
    width: 110px;
  }

  margin-bottom: 40px;
  fill: linear-gradient(90.05deg, $primary50 0%, $gradientRight 99.96%);
}

.errorCode {
  background: linear-gradient(90.05deg, $primary50 0%, $gradientRight 99.96%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

.goBack {
  width: 184px;
}
