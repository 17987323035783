@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/login.jpg");
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 400px;
  background-color: $surface10;
}

.buttonLink {
  color: $primary50;
  text-decoration: underline $primary40;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.button {
  margin-top: 40px;
}

.subtitle {
  margin-top: 24px;
  margin-bottom: 8px;
}

.password {
  margin-bottom: 40px;
}

.spinner {
  margin-top: 200px;
  display: flex;
  justify-content: center;
}
