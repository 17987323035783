@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  display: flex;
  width: 100%;
  padding: 0 40px;
  margin-top: 32px;
  flex-direction: column;
  height: 90vh;

  @include mobile {
    margin-top: 16px;
  }
}

// in the next line, the size is hardcoded because the mobile screen heading is H1, and in desktop is H4.
.title {
  @include mobile {
    font-size: 32px;
  }
}

.subtitle {
  margin-top: 10px;
}

.topContainer {
  display: flex;
  width: 90%;
}

.data {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 80%;

  @include mobile {
    margin-top: 22px;
    grid-template-columns: 1fr;
    height: 55%;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: row;

    @include mobile {
      flex-direction: column;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
  }
}

.button {
  color: $surface10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 16px;

  .button {
    &.left {
      float: left;
      margin-left: 40px;
      align-items: center;
    }

    &.right {
      float: right;
      margin-right: 40px;
    }
  }

  @include mobile {
    margin-left: 0;
  }
}

.text {
  display: flex;
  width: 40%;

  @include mobile {
    margin-top: 16px;
    width: 100%;
  }
}

.menu {
  display: none;
  margin-left: 39px;

  @include mobile {
    display: flex;
  }
}

.input {
  width: 100%;
  margin: 0 24px 40px 0;
}

.personalInfo {
  font-weight: bold;
  margin-top: 40px;

  @include mobile {
    margin-top: 0;
    display: none;
  }
}

.closeIcon {
  margin-right: 10px;
  filter: invert(37%) sepia(14%) saturate(4934%) hue-rotate(223deg) brightness(96%) contrast(85%);
}

.checkIcon {
  margin-left: 10px;
}
