// Only define styles exclusive to manager

@import "@mapix/common/src/assets/stylesheets/breakpoints";
@import "@mapix/common/src/assets/stylesheets/text-styles";

.initialContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-image: url("../../assets/images/login.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(#{$min-height} - #{$footer-height} - #{$header-height});

  @include mobile {
    background-position-y: 0;
  }
}
