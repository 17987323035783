@import "../../../assets/stylesheets/colors";
@import "../../../assets/stylesheets/mixins";

.input {
  margin-top: 32px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nuveiFieldWrapper {
  @extend .input;

  position: relative;
  display: flex;
  align-items: center;

  &Small {
    @extend .nuveiFieldWrapper;

    width: 48%;
  }
}

.cvvContainer {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
}

.nuveiField {
  width: 100%;
}

.errors {
  width: 100%;
  position: absolute;
  top: 105%;
  left: 16px;
}
