@import "../../assets/stylesheets/colors";

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid $secondary90;
  background-color: $surface10;
  border-radius: 2px;

  &::after {
    content: "";
    position: absolute;
    display: none;
  }
}

.label {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  .checkmark {
    &::after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid $surface10;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
    }

    &Small {
      @extend .checkmark;

      height: 15px;
      width: 15px;
      border: 1px solid $secondary80;

      &::after {
        left: 3px;
        top: 0;
        width: 4px;
        height: 8px;
      }
    }
  }

  &:hover {
    input {
      ~ {
        .checkmark {
          background-color: $secondary40;
          border: 2px solid $primary50;
        }
      }
    }

    input[disabled] {
      ~ {
        .checkmark {
          border: 2px solid $textNeutral30;
        }
      }
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ {
        .checkmark {
          background-color: $primary50;
          border-radius: 2px;
          border: 2px solid $primary50;

          &::after {
            display: block;
          }
        }
      }
    }
  }

  input[type="checkbox"][disabled] {
    &:checked {
      ~ {
        .checkmark {
          background-color: $textNeutral30;
          border: 2px solid $textNeutral30;
        }
      }
    }
  }
}
