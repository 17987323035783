@import "@mapix/common/src/assets/stylesheets/colors";

.label {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  align-items: center;

  input {
    appearance: none;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    cursor: pointer;
    border: 2px solid #999;
    transition: 0.2s all linear;
    margin: 0 5px 0 0;
    position: relative;
  }

  input:checked {
    border: 6px solid $primary50;
  }
}
