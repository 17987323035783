@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "assets/stylesheets/variables";

.titleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 900px;
  height: 150px;
  text-align: center;
  margin-top: 0;
  z-index: $z-index-welcome-title;

  @include mobile {
    width: 100%;
  }
}

.img {
  width: 100%;
  height: 90%;
  position: absolute;
}

.content {
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100%;
    margin-bottom: 16px;
  }
}

.subtitle {
  margin-top: 10px;
}

.title {
  margin-bottom: 0;
}

.blue {
  color: $primary50;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 100px;

  @include mobile {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    width: 100%;
  }
}

.card {
  text-align: center;
  width: 320px;
  height: 320px;
  background-color: white;
  position: relative;
  box-shadow: 0 2px 4px $secondary70 0.1;
  border-radius: 8px;
  padding-left: 32px;
  padding-right: 32px;

  @include mobile {
    margin-top: 50px;
    width: 200px;
    height: 200px;
  }
}

.cardTitle {
  margin-top: 0;
}

.cardSubtitle {
  bottom: 52px;
  position: absolute;
  text-align: center;
  width: 80%;

  @include mobile {
    font-size: 10px;
    width: 65%;
  }
}

.green {
  color: $tertiary50;
}
