@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;
  background-color: $primary10;
  width: $drawer-width;
  padding-top: 40px;
  align-items: center;
  min-height: $page-min-height;

  @include mobile {
    display: none;
  }
}

.elements {
  display: flex;
  flex-direction: column;
  height: 40%;
}

.elementsTenant {
  @extend .elements;

  height: 168px;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid transparent;
  margin-bottom: 34px;

  &:hover {
    background: $primary20;
  }
}

.selected {
  @extend .icon;

  background-color: $primary20;
  border-bottom-color: $primary30;
}

.iconColor {
  filter: invert(39%) sepia(82%) saturate(528%) hue-rotate(211deg) brightness(87%) contrast(91%);
}

.dollar {
  filter: invert(57%) sepia(7%) saturate(317%) hue-rotate(169deg) brightness(96%) contrast(88%);
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    height: 40px;
    background-color: black;
    border-radius: 6px;
    padding: 10px;
    z-index: $z-index-welcome-title;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Position the tooltip */
    position: absolute;
    top: 0;
    left: 120%;

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.iconGrey {
  filter: invert(56%) sepia(9%) saturate(263%) hue-rotate(169deg) brightness(97%) contrast(86%);
}
