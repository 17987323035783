@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 30px 32px;
  background-color: $surface10;
  width: 416px;
  border-radius: 8px;

  .row {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .title {
    align-self: center;
    margin-top: 16px;
    margin-bottom: 8px;
    text-align: center;
  }

  .paymentMargin {
    margin-top: 8px;
    text-align: center;
  }

  .paymentDate {
    margin-bottom: 5px;
    align-self: center;
  }

  .button {
    margin-top: 24px;
    width: 184px;
    align-self: center;
  }
}

.closeButton {
  display: flex;
  flex-direction: row;
  width: 30px;
  align-self: end;
}

.close {
  width: 30px;
  filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
}
