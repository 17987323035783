@import "@mapix/common/src/assets/stylesheets/colors";

.spinner {
  background-color: $surface10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.row {
  display: flex;
}
