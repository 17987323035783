@import "@mapix/common/src/assets/stylesheets/colors";

.rateTitle {
  margin-top: 40px;
}

.margin {
  margin-top: 8px;
  padding: 0;
}

.taskHistory {
  @extend .margin;

  text-decoration: underline;
}

.ratePill {
  margin: 24px 0 20px;
}

.message {
  max-width: 420px;
  margin-bottom: 20px;
}

.divider {
  border: 1px solid $secondary40;
  width: 100%;
  margin: 0;
}

.spaceBetween {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .price {
    font-weight: 600;
    margin-left: 8px;

    &Dashed {
      @extend .price;

      text-decoration: line-through;
    }
  }

  .acceptModifyWrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: row;

    .accept {
      margin-right: 26px;
    }

    .modify {
      display: flex;
      align-items: center;
      padding: 0;

      .editIcon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        filter: invert(54%) sepia(83%) saturate(1687%) hue-rotate(215deg) brightness(98%) contrast(90%);
      }
    }
  }
}

.column {
  @extend .spaceBetween;

  flex-direction: column;
}
