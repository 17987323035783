@import "./breakpoints";
@import "./text-styles";

@each $size in $margin-top-sizes {
  .mt-#{$size} {
    margin-top: #{$size}px;
  }
}

@each $size in $margin-bottom-sizes {
  .mb-#{$size} {
    margin-bottom: #{$size}px;
  }
}

@each $size in $margin-right-sizes {
  .mr-#{$size} {
    margin-right: #{$size}px;
  }
}

@each $size in $margin-left-sizes {
  .ml-#{$size} {
    margin-left: #{$size}px;
  }
}

@each $size in $margin-block-sizes {
  .m-block-#{$size} {
    margin-block: #{$size}px;
  }
}

@each $size in $margin-inline-sizes {
  .m-inline-#{$size} {
    margin-inline: #{$size}px;
  }
}

.text {
  &__heading1 {
    @include color-styles {
      @include text__heading1;
    }
  }

  &__heading2 {
    @include color-styles {
      @include text__heading2;
    }
  }

  &__heading3 {
    @include color-styles {
      @include text__heading3;
    }
  }

  &__heading4 {
    @include color-styles {
      @include text__heading4;
    }
  }

  &__heading5 {
    @include color-styles {
      @include text__heading5;
    }
  }

  &__heading6 {
    @include color-styles {
      @include text__heading6;
    }
  }

  &__body__large {
    @include color-styles {
      @include text__body__large;
    }
  }

  &__body__medium {
    @include color-styles {
      @include text__body__medium;
    }
  }

  &__body__small {
    @include color-styles {
      @include text__body__small;
    }
  }

  &__body__xsmall {
    @include color-styles {
      @include text__body__xsmall;
    }
  }

  &__body__overline {
    @include color-styles {
      @include text__body__overline;
    }
  }

  &__body__tiny {
    @include color-styles {
      @include text__body__tiny;
    }
  }

  &__button__small {
    @include color-styles {
      @include text__button__small;
    }
  }

  &__button__medium {
    @include color-styles {
      @include text__button__medium;
    }
  }

  &__button__large {
    @include color-styles {
      @include text__button__large;
    }
  }
}
