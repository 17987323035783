@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(#{$min-height} - #{$footer-height} - #{$header-height});
  padding: 32px 40px 40px;
  margin-bottom: 40px;

  p {
    display: block;
    margin: 1em 0;
  }

  h4 {
    font-weight: 300;
  }
}

.withoutSpace {
  p {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.bold {
  font-weight: bold;
}
