@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.title {
  margin-top: 24px;
}

.doubleSpecInput.doubleSpecInput {
  width: 280px;
}

.input {
  width: 280px;

  &.large {
    width: 320px;
  }

  &.small {
    width: 144px;
  }
}

.checkboxContainer {
  width: 538px;
  display: flex;
  flex-wrap: wrap;

  .checkbox {
    width: 170px;
    text-transform: capitalize;

    &:last-child {
      width: 210px;
    }
  }

  > * {
    margin-bottom: 24px;
  }
}

.fieldWrapper {
  display: flex;

  > * {
    margin-right: 24px;
  }
}

.formContainer {
  margin-top: 24px;

  > * {
    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .row {
    display: flex;

    > * {
      margin-right: 24px;
    }
  }
}

.alertEmailIcon {
  width: 15px;
  margin-left: 35px;
  margin-right: 5px;
}

.alertEmail {
  display: flex;
  align-items: center;
}

.alertEmailMargin {
  margin-bottom: 0;
}

.formTitle {
  margin-top: 56px;
}

.buttonRow {
  background-color: $surface10;

  .button {
    align-self: end;
  }
}

.step2 {
  margin-top: 24px;
}

.options {
  text-transform: capitalize;
}

.select {
  input {
    text-transform: capitalize;
  }
}

.trash {
  height: 20px;
  width: 20px;
  margin-right: 36px;

  &:last-child {
    margin-right: 0;
  }
}

.unitButton {
  height: 40px;
}

.unitButtonAlign {
  display: flex;
  align-items: center;
}

.marginDiscardUnit {
  margin-right: 24px;
}

.closeIcon {
  width: 15px;
  height: 15px;
  margin-right: 12px;
  filter: invert(37%) sepia(14%) saturate(4934%) hue-rotate(223deg) brightness(96%) contrast(85%);
}

.checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 12px;
  filter: invert(100%) sepia(79%) saturate(2%) hue-rotate(196deg) brightness(103%) contrast(101%);
}

.rentedUnit {
  display: flex;
  justify-content: center;
}

.step3 {
  margin-top: 24px;

  .save {
    margin-top: 40px;
  }
}

.infoIcon {
  height: 24px;
  width: 24px;
}

.icon {
  width: 56px;
  height: 56px;
}

.leaseSubtitle {
  margin-top: 14px;
  display: flex;
  width: 100%;
}

.alertIcon {
  margin-right: 12px;
}

.unitForm {
  margin-top: 56px;
}

.iconPlus {
  filter: invert(100%) sepia(100%) saturate(10%) hue-rotate(218deg) brightness(104%) contrast(103%);
  margin-left: 8px;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: $surface10;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  justify-content: right;
}

.saveButton {
  height: 48px;
  margin-right: 40px;
}

.addUnit {
  display: flex;
}

.plusIcon {
  margin-left: 8px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.errorContainer {
  margin-top: 16px;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  justify-content: flex-start;
}
