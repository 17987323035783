@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.cross {
  width: 24px;
  height: 24px;
  filter: invert(38%) sepia(82%) saturate(595%) hue-rotate(214deg) brightness(87%) contrast(95%);
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $surface20;

  .divider {
    border: 1px solid $secondary40;
    width: 100%;
    margin: 0;
  }

  .footerContent {
    padding-bottom: 8px;
    padding-top: 8px;

    .button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;

      @include mobile {
        height: 40px;
      }

      &.left {
        float: left;
        margin-left: 40px;

        @include mobile {
          margin-left: 16px;
        }
      }

      &.right {
        margin-right: 40px;

        @include mobile {
          margin-right: 16px;
          margin-left: 16px;
          display: flex;
          justify-content: center;
        }
      }

      &.second {
        margin-right: 20px;
      }

      &.backAndNextButton {
        display: flex;
        justify-content: space-between;
      }
    }

    .rightButtonContainer {
      display: flex;
      justify-content: flex-end;
    }

    .chevron {
      height: 24px;
      width: 24px;

      &.right {
        filter: invert(75%) sepia(92%) saturate(0%) hue-rotate(299deg) brightness(108%) contrast(109%);
      }

      &.left {
        filter: invert(55%) sepia(5%) saturate(5701%) hue-rotate(210deg) brightness(103%) contrast(87%);
      }
    }
  }
}

.marginLeft {
  margin-left: auto;
}

.rightButtons {
  display: flex;
  justify-content: flex-end;
}
