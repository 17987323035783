@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  background-image: url("../../assets/images/login.jpg");
  background-size: cover;
  justify-content: center;
  align-items: center;
  min-height: calc(#{$min-height} - #{$footer-height} - #{$header-height});
}

.buttonLink {
  color: $primary50;
  text-decoration: underline $primary40;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 400px;
  background-color: $surface10;
}
