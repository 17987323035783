@import "@mapix/common/src/assets/stylesheets/colors";

.cardContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 84px;
  background-color: $surface10;
  border-radius: 8px;
  padding: 14px 12px;

  .avatar {
    height: 48px;
    width: 48px;
    flex-shrink: 0;
  }

  .cardInfo {
    margin-left: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .cardName {
      margin-right: 16px;
    }
  }

  .categoryIconWrapper {
    margin-top: 6px;

    .categoryIcon {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }

  .button {
    height: 32px;
    width: 32px;
    background-color: $surface20;
    border-radius: 8px;
    position: absolute;
    right: 12px;
    bottom: 12px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .chevron {
      width: 16px;
      height: 16px;
      filter: invert(42%) sepia(12%) saturate(362%) hue-rotate(150deg) brightness(94%) contrast(80%);
    }
  }
}
