@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding-left: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.row {
  display: flex;
  flex-direction: row;
}

.card {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
}

.cardDescription {
  @extend .card;

  width: 60%;
}

.margin {
  margin-top: 20px;

  &LastRow {
    @extend .margin;

    margin-bottom: 20px;
  }
}

.marginSecondPhone {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
}

.photos {
  margin-top: 24px;
  display: flex;
  width: 90%;
  flex-wrap: wrap;
}

.photo {
  cursor: pointer;
  width: 210px;
  height: 210px;
  margin: 10px 20px 10px 0;
}

.italic {
  margin-top: 20px;
  font-style: italic;
}

.address {
  margin-top: 8px;
  width: 50%;
}

.contactInfo {
  width: 80%;
  justify-content: space-between;
  margin-top: 24px;

  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex-direction: column;
}
