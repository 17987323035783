@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;
}

.subtitle {
  margin-bottom: 24px;
}

.input {
  width: 280px;

  &.description {
    width: 710px;
  }

  &.large {
    width: 320px;
  }

  &.extralarge {
    width: 580px;
    margin-right: 20px;
  }
}

.checkboxContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  align-content: center;

  .checkbox {
    margin-right: 56px;
    text-transform: capitalize;
    align-items: center;
  }

  > * {
    margin-bottom: 24px;
  }
}

.dropdown {
  position: relative;
}

.searchColor {
  filter: invert(56%) sepia(77%) saturate(5538%) hue-rotate(232deg) brightness(97%) contrast(89%);
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 16px;
  background-color: #fff;
  max-height: 350px;
  overflow: auto;
  border: 1px solid $surface10;
  border-radius: 0 0 8px 8px;
  color: $textBody;
  align-items: flex-start;
  width: 580px;
  z-index: $select-z-index;
}

.listDropDown {
  margin-bottom: 16px;
}

.margin {
  margin-top: 72px;
  margin-bottom: 24px;
}

.firstMargin {
  margin-top: 24px;
  margin-bottom: 24px;
}

.icons {
  height: 24px;
  width: 24px;
  max-width: 24px;
}

.plusIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  filter: invert(38%) sepia(82%) saturate(595%) hue-rotate(214deg) brightness(87%) contrast(95%);
}

.contractorButton {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskButton {
  margin-left: 16px;
}

.items {
  margin-top: 10px;
  display: flex;
}

.area {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.iconCellLeftClass {
  min-width: 1%;
}

.addressSubtitle {
  margin-top: 8px;
}

.iconStyle {
  width: 56px;
  height: 56px;
  filter: invert(39%) sepia(82%) saturate(528%) hue-rotate(211deg) brightness(87%) contrast(91%);
}

.alertNotifyIcon {
  width: 15px;
  margin-left: 35px;
  margin-right: 5px;
}

.alertNotifyOptions {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
