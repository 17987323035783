@import "../../../assets/stylesheets/colors";
@import "../../../assets/stylesheets/mixins";

.dividerAndButtonWrapper {
  width: 100%;

  @include mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px 22px 16px;
  }

  .divider {
    border: 1px solid $secondary40;
    width: 100%;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 52px 0 0;

    @include mobile {
      margin: 24px 0 0;
    }

    .button {
      padding: 8px 0;
      width: 48%;

      @include mobile {
        height: 48px;
      }
    }
  }
}
