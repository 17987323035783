@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.img {
  width: 210px;
  height: 210px;
  object-fit: contain;
  object-position: center;
}

.closeButtonContainer {
  position: fixed;
  top: 40px;
  right: 40px;

  @include mobile {
    right: 30px;
    width: 44px;
    height: 44px;
    opacity: 0.8;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary70;
  }

  .closeButton {
    box-shadow: none;

    > button {
      margin-left: 0;
    }

    @include mobile {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(359deg) brightness(103%) contrast(103%);
    }
  }
}

.circle {
  position: absolute;
  background: $surface10;
  width: 34px;
  height: 34px;
  opacity: 0.8;
  top: 50%;
  border-radius: 100%;

  @include mobile {
    background: $secondary70;

    .chevron {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(359deg) brightness(103%) contrast(103%);
    }
  }

  &.left {
    left: 36px;
  }

  &.right {
    right: 36px;
  }
}

.overlayImage {
  object-fit: contain;
  object-position: center;
  max-width: 95%;
  max-height: 95%;
}
