@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
}

.addressSubtitle {
  margin-top: 8px;
  margin-bottom: 44px;
}

.icon {
  height: 40px;
  width: 40px;
  filter: invert(41%) sepia(59%) saturate(3015%) hue-rotate(231deg) brightness(95%) contrast(92%);
  margin-left: 10px;
}
