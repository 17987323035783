@import "../../../assets/stylesheets/colors";
@import "../../../assets/stylesheets/mixins";

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px;
  gap: 8px;
  position: relative;
  width: 512px;
  background: $surface10;
  box-shadow: 0 2px 4px $surface10;
  border-radius: 8px;

  .titleAndCrossContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .semiBold {
    font-weight: 600;
  }

  .crossIcon {
    width: 20px;
    height: 20px;
    filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);

    @include mobile {
      margin-top: 0;
      margin-right: 0;
    }
  }

  .uploadItemWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .fileTitles {
      margin: 20px 0;
    }
  }
}
