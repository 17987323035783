@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;
}

.iconStyle {
  width: 56px;
  height: 56px;
  filter: invert(39%) sepia(82%) saturate(528%) hue-rotate(211deg) brightness(87%) contrast(91%);
}

.alertNotifyIcon {
  width: 15px;
  margin-left: 35px;
  margin-right: 5px;
}

.alertNotifyOptions {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
