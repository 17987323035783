@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  width: 100%;
  padding: 0 16px;
  margin-top: 24px;
  border-radius: 40px;
}

.showing {
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 8px;
}

.iconHome {
  filter: invert(33%) sepia(18%) saturate(4562%) hue-rotate(228deg) brightness(89%) contrast(84%);
}

.marginLeft {
  margin-left: 16px;
}

.data {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 0 25px 0 16px;
    margin-bottom: 24px;
    box-shadow: 0 2px 19px 1px #a8afb64d;
    width: 100%;
    height: 120px;

    .title {
      font-weight: 600;
      margin-bottom: 4px;
    }

    .column {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 216px;
      height: 72px;
    }
  }
}

.iconBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  height: 49px;
  border-radius: 100%;
}

.iconBackgroundDollar {
  @extend .iconBackground;

  background-color: $primary10;
}

.iconBackgroundClaims {
  @extend .iconBackground;

  background-color: $info10;
}

.iconBackgroundHome {
  @extend .iconBackground;

  background-color: $yellow10;
}

.emptyState {
  margin-top: 40px;
}

.dollarIcon {
  filter: invert(32%) sepia(58%) saturate(1284%) hue-rotate(224deg) brightness(88%) contrast(88%);
}

.claimsIcon {
  filter: invert(28%) sepia(42%) saturate(3378%) hue-rotate(209deg) brightness(96%) contrast(92%);
}

.homeIcon {
  filter: invert(42%) sepia(69%) saturate(324%) hue-rotate(1deg) brightness(100%) contrast(88%);
}

.chevron {
  width: 24px;
  height: 24px;
  filter: invert(32%) sepia(58%) saturate(1284%) hue-rotate(224deg) brightness(88%) contrast(88%);
}
