.fetching {
  margin-top: 25%;
}

.formTitle {
  margin-top: 56px;
  margin-bottom: 20px;
}

.input {
  width: 280px;

  &.large {
    width: 320px;
  }

  &.small {
    width: 144px;
  }
}

.options {
  text-transform: capitalize;
}

.select {
  input {
    text-transform: capitalize;
  }
}

.step3 {
  margin-top: 24px;

  .save {
    margin-top: 40px;
  }
}

.trash {
  height: 20px;
  width: 20px;
  margin-right: 36px;

  &:last-child {
    margin-right: 0;
  }
}
