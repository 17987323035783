@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.selectContainer {
  position: relative;
  width: 100%;

  .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: $select-z-index;
    padding: 4px 8px;
    background-color: $surface10;
    max-height: 350px;
    overflow: auto;
    width: 100%;
    box-shadow: 0 2px 19px 1px $surface50;
    border-radius: 0 0 8px 8px;

    .item {
      text-align: left;
      padding: 10px 8px;
      border-bottom: 1px solid $secondary50;

      &:hover {
        background-color: $secondary20;
      }

      &:active {
        background-color: $secondary30;
      }

      &:last-child {
        border-bottom: none;
      }

      &Center {
        @extend .item;

        text-align: center;
      }
    }
  }
}
