@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.uploadedImageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: $surface30;
  padding: 8px;
  border-radius: 8px;

  @include mobile {
    background-color: $surface10;
  }

  > a {
    text-decoration: none;
  }

  @include mobile {
    padding: 8px;
  }

  .trashContainer {
    padding: 0;

    .trash {
      height: 24px;
      width: 24px;
      filter: invert(50%) sepia(36%) saturate(6396%) hue-rotate(228deg) brightness(93%) contrast(98%);
    }
  }

  .iconAndImgNameContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0;

    .file {
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  .crossContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background-color: $surface50;
    border-radius: 100%;
    margin-left: 20px;

    .cross {
      width: 16px;
      height: 16px;
      filter: invert(40%) sepia(15%) saturate(272%) hue-rotate(150deg) brightness(98%) contrast(84%);
    }
  }

  .rightButton {
    white-space: nowrap;
  }
}
