@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: $surface10;
  border-radius: 8px;

  @include mobile {
    width: 352px;
    padding: 24px 16px;
  }
}

.crossContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
}

.crossIcon {
  width: 20px;
  height: 20px;
  filter: invert(63%) sepia(11%) saturate(204%) hue-rotate(147deg) brightness(91%) contrast(90%);

  @include mobile {
    margin-top: 0;
    margin-right: 0;
  }
}

.subtitle {
  margin-bottom: 16px;

  @include mobile {
    margin-bottom: 4px;
  }
}

.password {
  margin-top: 24px;
  margin-bottom: 32px;
}

.data {
  display: flex;
  flex-flow: column wrap;
  margin-top: 24px;
  justify-content: space-between;
  width: 100%;

  @include mobile {
    margin-top: 0;
  }
}

.input {
  width: 320px;
}

.buttonSave {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  width: 296px;
  height: 36px;

  @include mobile {
    margin-top: 36px;
  }
}

.repeatPassword {
  margin-top: 48px;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.link {
  text-decoration: underline;
  margin-top: 16px;
}
