@use "sass:map";
@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

$text-colors: (
  primary: $primary50,
  primary40: $primary40,
  primary60: $primary60,
  primary50: $primary50,
  primary70: $primary70,
  primary80: $primary80,
  secondary70: $secondary70,
  secondary80: $secondary80,
  secondary90: $secondary90,
  tertiary40: $tertiary40,
  tertiary50: $tertiary50,
  textHeading: $textHeading,
  textBody: $textBody,
  textNeutral10: $textNeutral10,
  textNeutral20: $textNeutral20,
  textNeutral30: $textNeutral30,
  textNeutral40: $textNeutral40,
  textNeutral50: $textNeutral50,
  danger20: $danger20,
  danger30: $danger30,
  danger40: $danger40,
  danger50: $danger50,
  gradient50: $gradient50,
  surface10: $surface10,
  warning40: $warning40,
  warning50: $warning50,
  info30: $info30,
  info40: $info40,
  info50: $info50,
  success40: $success40,
  success50: $success50,
  yellow50: $yellow50,
);
$text-heading-sizes: (
  heading1: 56px,
  heading2: 48px,
  heading3: 40px,
  heading4: 32px,
  heading5: 24px,
  heading6: 16px
);
$text-heading-sizes-mobile: (
  heading1: 32px,
  heading2: 24px,
  heading3: 20px,
  heading4: 16px,
  heading5: 12px,
);
$text-body-sizes: (
  bodylarge: 20px,
  bodymedium: 16px,
  bodysmall: 14px,
  bodyxsmall: 12px,
  bodyoverline: 12px,
  bodytiny: 10px
);
$text-button-sizes: (
  buttonsmall: 14px,
  buttonmedium: 16px,
  buttonlarge: 20px
);
$text-body-sizes-mobile: (
  bodylarge: 16px,
  bodymedium: 14px,
  bodysmall: 12px,
  bodyoverline: 10px,
  bodytiny: 10px
);

@mixin text__heading1 {
  font-size: map.get($map: $text-heading-sizes, $key: heading1);

  @include mobile {
    font-size: map.get($map: $text-heading-sizes-mobile, $key: heading1);
  }
}

@mixin text__heading2 {
  font-size: map.get($map: $text-heading-sizes, $key: heading2);

  @include mobile {
    font-size: map.get($map: $text-heading-sizes-mobile, $key: heading2);
  }
}

@mixin text__heading3 {
  font-size: map.get($map: $text-heading-sizes, $key: heading3);

  @include mobile {
    line-height: 28px;
    font-weight: 600;
    font-size: map.get($map: $text-heading-sizes-mobile, $key: heading3);
  }
}

@mixin text__heading4 {
  line-height: 40px;
  font-size: map.get($map: $text-heading-sizes, $key: heading4);

  @include mobile {
    line-height: 32px;
    font-weight: 300;
    font-size: map.get($map: $text-heading-sizes-mobile, $key: heading4);
  }
}

@mixin text__heading5 {
  font-size: map.get($map: $text-heading-sizes, $key: heading5);
  font-weight: 300;

  @include mobile {
    font-size: map.get($map: $text-heading-sizes-mobile, $key: heading5);
  }
}

@mixin text__heading6 {
  font-weight: 600;
  font-size: map.get($map: $text-heading-sizes, $key: heading6);
}

@mixin text__body__large {
  font-size: map.get($map: $text-body-sizes, $key: bodylarge);

  @include mobile {
    font-size: map.get($map: $text-body-sizes-mobile, $key: bodylarge);
  }
}

@mixin text__body__medium {
  font-size: map.get($map: $text-body-sizes, $key: bodymedium);

  @include mobile {
    font-size: map.get($map: $text-body-sizes-mobile, $key: bodymedium);
  }
}

@mixin text__body__small {
  font-size: map.get($map: $text-body-sizes, $key: bodysmall);
  line-height: 20px;

  @include mobile {
    font-size: map.get($map: $text-body-sizes-mobile, $key: bodysmall);
  }
}

@mixin text__body__xsmall {
  font-size: map.get($map: $text-body-sizes, $key: bodyxsmall);
  line-height: 20px;
}

@mixin text__body__overline {
  font-size: map.get($map: $text-body-sizes, $key: bodyoverline);

  @include mobile {
    font-size: map.get($map: $text-heading-sizes-mobile, $key: bodyoverline);
  }
}

@mixin text__body__tiny {
  font-size: map.get($map: $text-body-sizes, $key: bodytiny);

  @include mobile {
    font-size: map.get($map: $text-heading-sizes-mobile, $key: bodytiny);
  }
}

@mixin text__button__small {
  font-size: map.get($map: $text-button-sizes, $key: buttonsmall);
}

@mixin text__button__medium {
  font-size: map.get($map: $text-button-sizes, $key: buttonmedium);
}

@mixin text__button__large {
  font-size: map.get($map: $text-button-sizes, $key: buttonlarge);
}

@mixin color-styles {
  @each $color in map.keys($text-colors) {
    &__#{$color} {
      color: map.get($text-colors, $color);
      @content;
    }
  }
}
