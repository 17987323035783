@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

$arrow-size: 6px;

.tooltip {
  position: relative;
  display: flex;
  cursor: pointer;
  width: fit-content;
  height: fit-content;

  .text {
    visibility: hidden;
    position: absolute;
    z-index: $z-index-tooltip;
    background-color: $secondary100;
    padding: 10px;
    border-radius: 8px;

    &::before {
      border: $arrow-size solid transparent;
      position: absolute;
      content: "";
    }

    &.topPositioned {
      top: -50%;
      left: 0;
      transform: translateY(-100%);

      &::before {
        bottom: -($arrow-size * 2);
        border-top-color: $secondary100;
      }
    }

    &.rightPositioned {
      left: 140%;
      top: -6px;

      &::before {
        border-right-color: $secondary100;
        right: 100%;
      }
    }

    &.bottomPositioned {
      bottom: -50%;
      left: 0;
      transform: translateY(100%);

      &::before {
        border-bottom-color: $secondary100;
        top: -($arrow-size * 2);
      }
    }

    &.leftPositioned {
      right: 0;
      transform: translateX(-100%);

      &::before {
        border-left-color: $secondary100;
        left: 100%;
      }
    }
  }

  &:hover {
    .text:not(.hidden) {
      visibility: visible;
    }
  }
}
