@import "@mapix/common/src/assets/stylesheets/colors";

.paymentContainer {
  width: 774px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $surface20;
  padding: 40px 32px 16px;
  box-shadow: 0 4px 8px $surface20;
  border-radius: 8px;

  .currency {
    width: 144px;
    margin-right: 8px;
  }

  .closeButton {
    margin-left: auto;

    .closeIcon {
      width: 20px;
      height: 20px;
      filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
    }
  }

  .billImage {
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }

  .formContainer {
    margin-top: 18px;
    align-self: start;
    width: 100%;

    .formTitle {
      margin-top: 32px;
      margin-bottom: 24px;
    }

    .input {
      width: 280px;
      margin-bottom: 48px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .buttonRow {
      float: right;

      .largeButton {
        padding: 8px 65px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
