@import "../../../assets/stylesheets/colors";

.container {
  display: flex;
  width: 100%;
  border: 1px solid $primary50;
  padding: 10px;
  height: 40px;
  border-radius: 8px;
  align-items: center;

  &Alert {
    justify-content: space-between;
    border: 1px solid $danger40;
  }

  .asterisk {
    margin-top: 5px;
    margin-right: 16px;
    letter-spacing: 2px;
  }

  .cardType {
    height: 24px;
    width: 34px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .trash {
    margin-left: auto;
    filter: invert(38%) sepia(45%) saturate(1910%) hue-rotate(226deg) brightness(87%) contrast(89%);
    cursor: pointer;
  }

  .creditCard {
    filter: invert(42%) sepia(7%) saturate(524%) hue-rotate(150deg) brightness(97%) contrast(90%);
    margin-right: 16px;
    margin-left: 4px;

    &Selected {
      @extend .creditCard;

      filter: invert(38%) sepia(93%) saturate(678%) hue-rotate(218deg) brightness(95%) contrast(93%);
    }
  }
}
