@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";

.container {
  padding: 24px 40px;
  width: 100%;

  @include mobile {
    padding: 8px 16px;
  }

  .field {
    flex-direction: column;
    width: 33%;

    * {
      margin-bottom: 8px;
    }

    @include mobile {
      width: 100%;
    }
  }

  .title {
    margin-top: 16px;

    @include mobile {
      margin-top: 24px;
    }
  }

  .underline {
    text-decoration: underline $textNeutral50;
  }

  .marginLeft {
    margin-left: auto;
  }
}
