.street {
  width: 412px;
}

.streetSmall {
  width: 242px;
}

.input {
  width: 280px;
}

.smallInput {
  width: 144px;
}

.formContainer {
  margin: 24px 0 34px;
  display: flex;

  .row {
    display: flex;
    gap: 24px;

    &WithMargins {
      @extend .row;

      margin: 34px 0;
    }
  }

  .mapContainer {
    margin-right: 32px;

    .map {
      width: 604px;
      height: 224px;
    }
  }
}

.formTitle {
  margin-top: 56px;
}
